import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { FormFooter, FormHeader } from "../utilities/OuterFormLayout";
import { MainFormProps } from "../../../models/FormModel";
import { FormEvent } from "react";
import FormElementMapping from "../handlers/handleFormMapping";
import handleFormSubmit from "../handlers/handleFormSubmit";

const CreateCustomerMainForm = ({ openForm, closeForm }: MainFormProps) => {
  const dispatch = useAppDispatch();
  const allCustomersID = useAppSelector((state) => state.data.allCustomersID);
  const currentFormData = useAppSelector((state) => state.formData);
  const currentPageKey = useAppSelector((state) => state.form.pageKey);
  const formElementMapping = {
    ...FormElementMapping(),
  };

  if (openForm !== true) return null;

  return (
    <div
      id="ModelContainer"
      className="fixed inset-0 bg-black flex justify-center items-center bg-opacity-20 backdrop-blur-sm w-full"
    >
      <div className="p-3 bg-white w-10/12 md:w-1/2 max-w-5xl lg:1/3 shadow-inner border-e-emerald-600 rounded-lg">
        <form
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            handleFormSubmit(e, dispatch, currentFormData, allCustomersID);
          }}
        >
          <div className="flex flex-col max-h-screen justify-between">
            <FormHeader title="Onboard New Customer Form" onClose={closeForm} />
            <div className="overflow-y-auto flex-grow p-2">
              {
                formElementMapping[
                  currentPageKey as keyof typeof formElementMapping
                ]
              }
            </div>
            <FormFooter />
          </div>
        </form>
      </div>
    </div>
  );
};

export { CreateCustomerMainForm };
