import FormInput from "../utilities/FormInput";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { setFormValid, setFormInvalid } from "../../../redux/formSlice";
import {
  setCustomerNameId,
  setTenantId,
  setSelectedProducts,
  removeSelectedProducts,
  setIsGECustomer
} from "../../../redux/formDataSlice";
import { useEffect } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const FormCreateCustomer = () => {
  const allCustomersID = useAppSelector((state) => state.data.allCustomersID);
  const currentCustomerName = useAppSelector((state) => state.formData.company_name);
  const currentCustomerId = useAppSelector((state) => state.formData.id);
  const currentTenantId = useAppSelector((state) => state.formData.tid);
  const currentSelectedProducts = useAppSelector((state) => state.formData.selected_products);
  const currentAllowedServices = useAppSelector((state) => state.form.allowedServices);
  const isGECustomer = useAppSelector((state) => state.formData.is_ge_customer);
  const allowedServices = useAppSelector((state) => state.form.allowedServices);
  const isServiceEmpty = allowedServices.services.every((service) => service.name === "" && service.id === "");

  const dispatch = useAppDispatch();

  const fieldValue = {
    name: currentCustomerName,
    tenantId: currentTenantId,
    services: currentSelectedProducts
  };

  const isFieldValidated = Object.values(fieldValue).every(
    (value) => value !== "" && value.length > 0
  );

  useEffect(() => {
    if (isFieldValidated) dispatch(setFormValid());
    else dispatch(setFormInvalid());
  }, [isFieldValidated]);
  
  useEffect(() => {
    if (allCustomersID.includes(currentCustomerId)) {
      dispatch(setFormInvalid());
    }
  }, [allCustomersID, currentCustomerId]);
  
  return (
    <div>
      {isServiceEmpty ? (
        <div className="flex flex-col items-center justify-center">
          <AiOutlineLoading3Quarters className="animate-spin h-20 w-20 text-slate-100 mb-6" />
          <span className="font-semibold">Loading Form Details...</span>
        </div>
      ) : (
        <>
          <span className="text-xl font-bold mb-3">Customer Details</span>
          <FormInput
            title="Customer Display Name"
            name="name"
            extraStyle="font-bold"
            value={currentCustomerName}
            onChangeAction={(e) => dispatch(setCustomerNameId(e.target.value))}
          />
          {allCustomersID.includes(currentCustomerId) && (
            <span className="text-red-500 text-sm">
              Customer ID already exists. Please enter a new customer name.
            </span>
          )}
          <FormInput
            title="Tenant ID"
            name="tenant-id"
            extraStyle="font-bold"
            value={allCustomersID.includes(currentCustomerId) ? "" : currentTenantId}
            disabled={allCustomersID.includes(currentCustomerId) ? true : false}
            onChangeAction={(e) => dispatch(setTenantId(e.target.value))}
          />
          <div className="service-checkbox flex items-center justify-between">
            <fieldset className="mb-3">
              <label className="text-sm font-semibold leading-6 text-gray-900">
                Is the customer a G&E customer? (NOTE: You need to have G&E customer access to onboard a G&E customer)
              </label>
              <div className="control mt-3">
                <div key="is_ge_customer" className="control">
                  <input
                    className="h-4 w-4 rounded border-gray-300 dark:focus:ring-gray-300 focus:ring-2 dark:ring-offset-gray-800"
                    type="checkbox"
                    id="is_ge_customer_check_box"
                    name="services"
                    checked={isGECustomer ? true : false}
                    onChange={(e) =>
                      e.target.checked
                        ? dispatch(setIsGECustomer(true))
                        : dispatch(setIsGECustomer(false))
                    }
                  />
                  <label className="font-medium text-gray-900 m-2">G&E customer</label>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="service-checkbox flex items-center justify-between">
            <fieldset className="mb-3">
              <label className="text-sm font-semibold leading-6 text-gray-900">
                Select service(s) for the customer:
              </label>
              <div className="control mt-3">
                {currentAllowedServices.services.map((service) => {
                  return (
                    <div key={service.id} className="control">
                      <input
                        className="h-4 w-4 rounded border-gray-300 dark:focus:ring-gray-300 focus:ring-2 dark:ring-offset-gray-800"
                        type="checkbox"
                        id={service.id}
                        name="services"
                        value={service.name}
                        disabled={
                          allCustomersID.includes(currentCustomerId) ? true : false
                        }
                        checked={allCustomersID.includes(currentCustomerId) ? false : currentSelectedProducts.includes(service.name)}
                        onChange={(e) =>
                          e.target.checked
                            ? dispatch(setSelectedProducts(e.target.value))
                            : dispatch(removeSelectedProducts(e.target.value))
                        }
                      />
                      <label
                        className="font-medium text-gray-900 m-2"
                        htmlFor={service.id}
                      >
                        {service.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </fieldset>
          </div>
        </>
      )}
    </div>
  );
};

export { FormCreateCustomer };
