import { createSlice } from "@reduxjs/toolkit"
import { DailyCount, IOC, IOCBlacklistEntry, SherlockCounts, SherlockCustomer, SherlockMetric, Sighting } from "../models/SherlockModel"
import { SightedIOC } from "../models/ReportModel"

interface SherlockState {
    pagedIOCs: IOC[]
    pagedSightings: Sighting[]
    pageSize: number
    
    pagedSightingsEnd: boolean
    pagedIOCsEnd: boolean
    streamSightings: Sighting[]

    streamSightingsDisplay: boolean
    pagedSightingsDisplay: boolean
    pagedIOCsDisplay: boolean

    additionalIOCs: IOC[]
    additionalSightings: Sighting[]

    customers: SherlockCustomer[]
    totalCounts: SherlockCounts | null
    dailyMetrics: DailyCount[]
    weeklyMetrics: SherlockMetric[]
    lastWeekIOCsRank: SightedIOC[]
    lastMonthIOCsRank: SightedIOC[]
    iocBlacklist: IOCBlacklistEntry[]
}

const initialState: SherlockState = {
    pagedIOCs: [],
    pagedSightings: [],
    // pageSize should be same as query limit in backend api
    pageSize: 25,
    
    pagedSightingsEnd: false,
    pagedIOCsEnd: false,
    streamSightings: [],

    streamSightingsDisplay: true,
    pagedSightingsDisplay: true,
    pagedIOCsDisplay: true,

    additionalIOCs: [],
    additionalSightings: [],

    customers: [],
    totalCounts: null,
    dailyMetrics: [],
    weeklyMetrics: [],
    lastWeekIOCsRank: [],
    lastMonthIOCsRank: [],
    iocBlacklist: []
}

export const sherlockSlice = createSlice({
    name: 'sherlock',
    initialState,
    reducers: {
        setPagedSightings: (state, action) => {
            state.pagedSightings.push(...action.payload)
            if (action.payload.length < state.pageSize) {
                state.pagedSightingsEnd = true
            }
        },
        setPagedIOCs: (state, action) => {
            state.pagedIOCs.push(...action.payload)
            if (action.payload.length < state.pageSize) {
                state.pagedIOCsEnd = true
            }
        },
        setStreamSightings: (state, action) => {
            state.streamSightings.unshift(action.payload)
            // keep only 50 sightings in stream sightings
            if (state.streamSightings.length > 50) {
                state.streamSightings.pop()
            }
        },
        setStreamSightingsDisplay: (state, action) => {
            state.streamSightingsDisplay = action.payload
        },
        setPagedSightingsDisplay: (state, action) => {
            state.pagedSightingsDisplay = action.payload
        },
        setPagedIOCsDisplay: (state, action) => {
            state.pagedIOCsDisplay = action.payload
        },
        setAdditionalIOCs: (state, action) => {
            state.additionalIOCs.push(...action.payload)
        },
        setAdditionalSightings: (state, action) => {
            state.additionalIOCs.push(...action.payload)
        },        
        setSherlockCustomers: (state, action) => {
            state.customers = action.payload
        },
        setSherlockTotalCounts: (state, action) => {
            state.totalCounts = action.payload
        },
        setSherlockDailyMetrics: (state, action) => {
            state.dailyMetrics = action.payload
        },
        setSherlockWeeklyMetrics: (state, action) => {
            state.weeklyMetrics = action.payload
        },
        setLastWeekIOCsRank: (state, action) => {
            state.lastWeekIOCsRank = action.payload
        },
        setLastMonthIOCsRank: (state, action) => {
            state.lastMonthIOCsRank = action.payload
        },
        setIOCBlacklist: (state, action) => {
            state.iocBlacklist = action.payload
        }
    }
})

export const {setPagedSightings, setPagedIOCs, setStreamSightings,
    setStreamSightingsDisplay, setPagedSightingsDisplay, setPagedIOCsDisplay,
    setAdditionalIOCs, setAdditionalSightings,
    setSherlockCustomers, setSherlockTotalCounts, setSherlockDailyMetrics, setSherlockWeeklyMetrics,
    setLastWeekIOCsRank, setLastMonthIOCsRank, setIOCBlacklist
} = sherlockSlice.actions

export default sherlockSlice.reducer