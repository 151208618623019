import MenuBar from "../components/MenuBar";
import TopNavBar from "../components/TopNavBar";
import SightingWebsocket from "../components/sherlock/SightingWebsocket";
import { activateFullScreenPrompt } from "../redux/fullScreenPromptSlice";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { getPagedSherlockData } from "../service/apiAccessLogic";
import PageNotAccessable from "./PageNotAccessable";
import { msalInstance } from "../index"
import { setPagedIOCs, setPagedSightings } from "../redux/sherlockSlice";
import PagedSightingsTable from "../components/sherlock/PagedSightingsTable";
import PagedIOCsTable from "../components/sherlock/PagedIOCsTable";
import Theme from "../Theme"
import SherlockBackground from '../resource/DEFEND-Main-Services.jpg'

const SherlockPage = () => {
    const sherlockSubId = useAppSelector(state => state.user.sherlockId)
    const sightings = useAppSelector(state => state.sherlock.pagedSightings)
    const iocs = useAppSelector(state => state.sherlock.pagedIOCs)
    const dispatch = useAppDispatch()
    
    if (sherlockSubId === "invalid") {
        return <PageNotAccessable />
    } else {
        return (
            <div className={`flex ${Theme.Colors.DarkWebBg}`} onLoad={() => {
                if (Object.keys(sightings).length !== 0 || Object.keys(iocs).length !== 0) return 

                dispatch(activateFullScreenPrompt("loading"))

                getPagedSherlockData(msalInstance, "indicators").then((response) => {
                    dispatch(setPagedIOCs(response.data))
                }).catch(()=>{
                    dispatch(activateFullScreenPrompt("error"))
                })

                getPagedSherlockData(msalInstance, "sightings").then((response) => {
                    dispatch(setPagedSightings(response.data))
                    dispatch(activateFullScreenPrompt("false"))
                }).catch(()=>{
                    dispatch(activateFullScreenPrompt("error"))
                })


            }}>
                <MenuBar onPage="sherlock" />
                <div className="w-full flex flex-col items-center min-h-screen pl-44 bg-cover" style={{ backgroundImage: `url(${SherlockBackground})`}} >
                    <TopNavBar />
                    <SightingWebsocket />
                    <PagedSightingsTable />
                    <PagedIOCsTable />
                </div>
            </div>
        )        
    }
}

export default SherlockPage