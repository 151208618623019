import { MdErrorOutline, MdOutlineCheckCircleOutline } from 'react-icons/md'
/**
 * All style settings expect FULL tailwind class name, i.e.("bg-orange-500, text-gray-50")
 */

interface ReportStatusProp {
    isReady: Boolean;
    text: String;
    extraStyle?: string
}

function ReportStatus({isReady = false, text, extraStyle = ""} : ReportStatusProp) {


    return(
        <div className= {` w-full ${extraStyle}`}>
            {isReady ?
                <div className="flex w-fit items-center">
                    <MdOutlineCheckCircleOutline className=" h-5 w-5 text-ui-lime"></MdOutlineCheckCircleOutline>
                    <h2 className="text-sm ml-1 text-ui-lime">{text}</h2>
                </div> 
                :               
                <div className="flex w-fit items-center">
                    <MdErrorOutline className=" h-5 w-5 text-orange-400"></MdErrorOutline>
                    <h2 className="text-sm ml-1 text-orange-400">{text}</h2>
                </div>
            }        
        </div>
    )
}

export default ReportStatus