import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";

const ConfirmActionModal = ({ onSubmit, closeForm, title, description }: any) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center mb-4">
          <div className="flex flex-col">
            <h1 className="text-lg font-bold">{title}</h1>
          </div>
          <button onClick={closeForm} className="p-2 text-sm bg-gray-200 rounded hover:bg-gray-300"><MdOutlineClose size={20} /></button>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(description);
          closeForm();
        }}>
          <div className="mb-4">
            <span>{description}</span>
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Confirm</button>
        </form>
      </div>
    </div>
  );
};

export default ConfirmActionModal;
