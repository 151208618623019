import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { FormHeaderProps } from "../../../models/FormModel";
import { CloseBtn, FinishBtn, NextBtn, PrevBtn } from "./FormButton";
import { clearFormData } from "../../../redux/formDataSlice";
import { resetFormType, resetPageKey } from "../../../redux/formSlice";

const FormHeader = ({ onClose, title }: FormHeaderProps) => {
  const dispatch = useAppDispatch();
  return (
    <div className="form-header flex items-center justify-between p-1 mb-2 border-b dark:border-gray-300">
      <span className="font-semibold py-3 text-xl">{title}</span>
      <div
        id="closeBtn"
        onClick={() => {
          dispatch(resetFormType());
          dispatch(resetPageKey());
          dispatch(clearFormData());
          {onClose()};
        }}
      >
        <CloseBtn />
      </div>
    </div>
  );
};

const FormFooter = () => {
  const currentSelectedProducts = useAppSelector((state) => state.formData.selected_products);
  const currentPageKey = useAppSelector((state) => state.form.pageKey);

  if (currentPageKey === "Complete") return null;

  return (
    <div>
      {renderFooter(
        currentPageKey,
        currentSelectedProducts
      )}
    </div>
  );
};

const renderFooter = (
  currentPageKey: string,
  currentSelectedProducts: string[]
) => {
  const isFirstStep = currentPageKey === "Info";
  const isLastStep =
    currentPageKey ===
    currentSelectedProducts[currentSelectedProducts.length - 1];

  const FooterStyle =
    "form-footer flex items-center px-1 pt-3 border-t dark:border-gray-300";
  switch (currentPageKey) {
    case "Info":
      return (
        <div className={`${FooterStyle} justify-end`}>
          <NextBtn />
        </div>
      );
    default:
      return (
        <div className={`${FooterStyle} justify-between`}>
          {!isFirstStep && <PrevBtn />}
          {!isLastStep && <NextBtn />}
          {isLastStep && <FinishBtn />}
        </div>
      );
  }
};

export { FormHeader, FormFooter };
