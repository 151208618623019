import { useState } from "react"
import RoundButton from "../utility/RoundButton"
import Theme from "../../Theme"
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { getSherlockIOCBlacklistEntryById, getSherlockIndicatorById, putSherlockIOCToBlacklist } from "../../service/apiAccessLogic"
import { msalInstance } from "../.."
import { IOC, IOCBlacklistEntry } from "../../models/SherlockModel"
import { setAdditionalIOCs } from "../../redux/sherlockSlice"
import { parseFeedsSource } from "../../service/dataLogic"

const IndicatorTogglePanel = () => {
    const [textId, setTextId] = useState<string>("")
    const [indicator, setIndicator] = useState<IOC | undefined>(undefined)
    const [iocBlacklistEntry, setIOCBlacklistEntry] = useState<IOCBlacklistEntry | undefined>(undefined)
    const [iocChecked, setIocChecked] = useState<boolean>(false)
    const [searchedIOCBlacklistEntry, setSearchedIOCBlacklistEntry] = useState<boolean>(false)
    type PanelStatus = "" | "searching" | "found" | "notFound" | "sent" | "updated" | "failed"
    const [panelStatus, setPanelStatus] = useState<PanelStatus>("")
    const [reason, setReason] = useState<string>("")
    const pagedIOCs = useAppSelector(state => state.sherlock.pagedIOCs)
    const additionalIOCs = useAppSelector(state => state.sherlock.additionalIOCs)
    const dispatch = useAppDispatch()

    const updateIndicatorId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextId(event?.target.value)
        setPanelStatus("")
        setIocChecked(false)
        setIndicator(undefined)
        setIOCBlacklistEntry(undefined)
        setSearchedIOCBlacklistEntry(false)
        setReason("")
    }

    const checkIndicator = () => {
        setPanelStatus("searching")
        const encoded_id = btoa(textId.replace(/\s/g, ''))

        let ioc = pagedIOCs.find(item => item.id === encoded_id)
        if (ioc) {
            setIocChecked(true)
            setIndicator(ioc)
            setPanelStatus("found")             
            return
        }
    
        ioc = additionalIOCs.find(item => item.id === encoded_id)
        if (ioc) {
            setIocChecked(true)
            setIndicator(ioc)
            setPanelStatus("found")             
            return            
        }
    
        getSherlockIndicatorById(msalInstance, encoded_id).then((response) => {
            setIocChecked(true)
            setIndicator(response.data[0])
            setPanelStatus("found") 
            dispatch(setAdditionalIOCs(response.data))
        }).catch((error) => {
            if (error.response && error.response.status === 404) {
                setPanelStatus("notFound")              
            }
        })        
    }

    const checkIOCBlacklistEntry = () => {
        const encoded_id = btoa(textId.replace(/\s/g, ''))

        getSherlockIOCBlacklistEntryById(msalInstance, encoded_id).then((response) => {
            if (response.data.length > 0) {
                setIOCBlacklistEntry(response.data[0])
            }
        }).catch((error) => {
            if (error.response && error.response.status === 404) {
                setIOCBlacklistEntry(undefined)        
            }
        }) 
        setSearchedIOCBlacklistEntry(true)     
    }

    const renderIndicatorDetails = () => {
        if (indicator === undefined) return 
        if (!searchedIOCBlacklistEntry) return 

        return (
            <div className="pl-4 pt-2 text-sm font-light">
            <div className="font-medium">Indicator details:</div>
            <table>
                <tbody>
                    <tr>
                        <td>ID:</td>
                        <td>{indicator.id}</td>
                    </tr>
                    <tr>
                        <td>Info:</td>
                        <td>{indicator.info}</td>
                    </tr> 
                    <tr>
                        <td>Indicator ID:</td>
                        <td>{indicator.indicator_id}</td>
                    </tr>                     
                    <tr>
                        <td>Org ID:</td>
                        <td>{indicator.org_id}</td>
                    </tr>  
                    <tr>
                        <td>Timestamp:</td>
                        <td>{indicator.timestamp}</td>
                    </tr>  
                    <tr>
                        <td>Expiry Date:</td>
                        <td>{indicator.expiry_date}</td>
                    </tr> 
                    <tr>
                        <td>Feeds:</td>
                        <td>{parseFeedsSource(indicator.feeds)}</td>
                    </tr> 
                    <tr>
                        <td className="font-medium">Blacklist Details:</td>
                        <td>{
                            iocBlacklistEntry === undefined ? 
                                "Indicator is in Sherlock and not blocked" 
                                : 
                                `Processed - ${iocBlacklistEntry.processed}; Re-enabled - ${iocBlacklistEntry.deleted}. ${!iocBlacklistEntry.processed ? "Please wait for Sherlock processing this indicator" : ""}`
                            }
                        </td>
                    </tr>                                                                                               
                </tbody>
            </table>
            </div>
        )
    }

    const reasonForUpdate = () => {
        if (indicator === undefined) return 
        if (!searchedIOCBlacklistEntry) return 
        
        return (
            <div className="pl-4 pt-4 text-sm font-light">
                <div className="font-medium">Reason For Update:</div>
                <textarea className="w-[90%]" rows={4} value={reason} placeholder="Please provide reasons before update indicator (minimum 5 words)." onChange={(event) => { setReason(event.target.value) }} />
            </div>
        )
    }

    const blacklistToggle = async () => {
        setPanelStatus("sent")
        let doBlacklist = iocBlacklistEntry === undefined ? true : false
        const reqObj = {
            "id": indicator?.id,
            "blacklist": doBlacklist,
            "reason": reason
        }

        await putSherlockIOCToBlacklist(msalInstance, JSON.stringify(reqObj))
            .then(() => {
                setPanelStatus("updated")
            })
            .catch ((error) => {
                if (error.response && error.response.status === 500) {
                    setPanelStatus("failed")
                }            
            }
            )

        setIocChecked(false)
        setIndicator(undefined)
        setIOCBlacklistEntry(undefined)
        setSearchedIOCBlacklistEntry(false)  
        setReason("")      
    }

    return (
        <div className={`w-[90%] max-w-screen-2xl my-5 bg-white bg-opacity-90`}>
            <div className="m-2">Indicator Manager</div>
            <div className="m-2 w-[100%] grid grid-cols-4">
                <div className="col-span-2 flex flex-row justify-between">
                    <div>Indicator: </div>
                    <input className="w-[80%] border border-gray-300" type="text" value={textId} onChange={ updateIndicatorId }/>
                </div>
                <div className="col-span-2 flex flex-row justify-end">
                    <RoundButton text={"Check Indicator"} colour={Theme.Colors.Primary} extraStyle={"mx-3"} 
                        disabled={textId === ""} 
                        onClickAction={() => {
                            checkIndicator()
                            checkIOCBlacklistEntry() 
                        }} 
                    /> 
                    <RoundButton text={ 
                            iocBlacklistEntry === undefined ?
                                "Block Indicator" :
                                "Enable Indicator"
                        } 
                        colour={Theme.Colors.Error} extraStyle={"mx-3"} 
                        disabled={
                            (iocChecked && !searchedIOCBlacklistEntry)
                            ||
                            (!iocChecked && indicator === undefined)
                            || 
                            (reason.trim().split(" ").length < 5)
                            ||
                            (iocBlacklistEntry !== undefined && !iocBlacklistEntry.processed)
                            ||
                            (iocBlacklistEntry !== undefined && iocBlacklistEntry.deleted)
                        } 
                        onClickAction={() => blacklistToggle() } 
                    />                     
                </div>
            </div>  
            {
                {
                    "":
                    <div></div>,
                    "searching":
                    <div className="m-2">Searching IOC...</div>,
                    "found":
                    <div className="m-2">
                        {renderIndicatorDetails()}
                        {reasonForUpdate()}
                    </div>,
                    "notFound":
                    <div className="m-2">Indicator not found.</div>,
                    "sent":
                    <div className="m-2">Request sent. Waiting for response...</div>,
                    "updated":
                    <div className="m-2">Sherlock started to process indicator. Please check later to confirm.</div>,
                    "failed":
                    <div className="m-2">Failed to update indicator.</div>
                }[panelStatus]
            }                   
        </div>
    )
}

export default IndicatorTogglePanel