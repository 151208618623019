import { ChangeEventHandler } from "react";

interface FormInputProps {
  title: string;
  name: string;
  id?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  extraStyle?: string;
  placeholder?: string;
  onChangeAction?: ChangeEventHandler<HTMLInputElement>;
}

const FormInput = ({
  title,
  name,
  id = name,
  type = "text",
  required = true,
  disabled = false,
  value,
  extraStyle,
  placeholder,
  onChangeAction,
}: FormInputProps) => {
  return (
    <div className="mb-3">
      <label className={`block text-gray-700 text-sm my-2 ${extraStyle}`}>
        {title}
      </label>
      <input
        id={id}
        type={type}
        name={name}
        required={required}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onChangeAction}
        className="border-2 border-gray-400 rounded-lg w-full p-2"
      />
    </div>
  );
};

export default FormInput;
