import { useMemo, useState, useEffect } from "react";
import { useReactTable, ColumnResizeMode, getCoreRowModel, ColumnDef, flexRender, PaginationState } from '@tanstack/react-table'
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { IOC } from '../../models/SherlockModel';
import { msalInstance } from "../../index"
import { getPagedSherlockData } from "../../service/apiAccessLogic";
import { setPagedIOCs } from "../../redux/sherlockSlice";

const defaultClientColumns: ColumnDef<IOC>[] = [
    {
        header: "IOCs",
        footer: props => props.column.id,
        columns: [
            {
                accessorFn: row => row.timestamp.replace("T", " ").slice(0, row.timestamp.indexOf(".")),
                id: 'timestamp',
                header: () => <span>Timestamp</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'threat_level_id',
                header: () => <span>Threat Level</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'info',
                header: () => <span>Info</span>,
                footer: props => props.column.id
            },
            {
                accessorFn: row => row.expiry_date.replace("T", " ").slice(0, row.expiry_date.indexOf(".")),
                id: 'expiry_date',
                header: () => <span>Expiry Date</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'indicator_id',
                header: () => <span>Indicator ID</span>,
                footer: props => props.column.id
            }                                                                  
        ]
    }
]

const defaultEditorColumns: ColumnDef<IOC>[] = [
    {
        header: "IOCs",
        footer: props => props.column.id,
        columns: [
            {
                accessorFn: row => atob(row.id),
                id: 'id',
                header: () => <span>Indicator</span>,
                footer: props => props.column.id
            },
            {
                accessorFn: row => row.timestamp.replace("T", " ").slice(0, row.timestamp.indexOf(".")),
                id: 'timestamp',
                header: () => <span>Timestamp</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'threat_level_id',
                header: () => <span>Threat Level</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'info',
                header: () => <span>Info</span>,
                footer: props => props.column.id
            },
            {
                accessorFn: row => row.expiry_date.replace("T", " ").slice(0, row.expiry_date.indexOf(".")),
                id: 'expiry_date',
                header: () => <span>Expiry Date</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'indicator_id',
                header: () => <span>Indicator ID</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'org_id',
                header: () => <span>Org ID</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'orgc_id',
                header: () => <span>Orgc ID</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'published',
                header: () => <span>Published</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'locked',
                header: () => <span>Locked</span>,
                footer: props => props.column.id
            }                                                                  
        ]
    }
]

const PagedIOCsTable = () => {
    const userMode = useAppSelector(state => state.user.userMode)
    const iocs = useAppSelector(state => state.sherlock.pagedIOCs)
    const pageSizeSetting = useAppSelector(state => state.sherlock.pageSize)
    const pageEnd = useAppSelector(state => state.sherlock.pagedIOCsEnd)
    const display = useAppSelector(state => state.sherlock.pagedIOCsDisplay)
    const dispatch = useAppDispatch()

    const [columns, setColumns] = useState<ColumnDef<IOC>[]>([])
    const [columnResizeMode] = useState<ColumnResizeMode>("onChange")

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: pageSizeSetting
    })

    const paginatedIOCs = iocs.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
    
    const pagination = useMemo(() => ({
        pageIndex,
        pageSize
    }), [pageIndex, pageSize])

    const table = useReactTable({
        data: paginatedIOCs || [],
        columns,
        pageCount: Math.ceil(iocs.length / pageSize),
        state: { pagination },
        onPaginationChange: setPagination,
        manualPagination: true, 
        columnResizeMode,
        getCoreRowModel: getCoreRowModel()
    })

    useEffect(() => {
        if (pageEnd) return 
        if (iocs.length === pageSize || (pageIndex + 1) * pageSize === iocs.length) {
            if (iocs.length === 0) return 
            getPagedSherlockData(msalInstance, "indicators", iocs[iocs.length-1]["_ts"], iocs[iocs.length-1]["uuid"])
                .then((response) => dispatch(setPagedIOCs(response.data)))
                .catch((err) => {console.error("Error fetching iocs", err)})           
        }
    }, [pageIndex, pageSize, iocs, dispatch, pageEnd])

    useEffect(() => {
        if (userMode === "editor") {
            setColumns([...defaultEditorColumns])
        } else {
            setColumns([...defaultClientColumns])
        }
    }, [userMode])

    return (
        <div className={`${display?"block":"hidden"} w-[90%] max-w-screen-2xl my-5 shadow-2xl bg-white bg-opacity-90 overflow-x-auto`}>
            {/* Table body */}
            <table 
                className="border border-gray-300 w-auto"
                style={{
                width: table.getCenterTotalSize(),
                }}
            >
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="w-auto h-7">
                    {headerGroup.headers.map(header => (
                    <th
                        key={header.id}
                        colSpan= {header.colSpan}
                        className={`border border-gray-300 p-1 text-center font-bold h-7 relative`}
                        style= {{
                            width: header.getSize(),
                            whiteSpace: "nowrap"
                        }}
                        >
                        {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                        )}
                        <div
                            className={`absolute right-0 top-0 h-full w-1.5 bg-gray-700 cursor-col-resize select-none ${header.column.getIsResizing() ? "bg-blue-500 opacity-100" : "opacity-0"}`}                      
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            style={{
                            transform:
                            columnResizeMode === 'onEnd' &&
                                header.column.getIsResizing() ?  
                                    `translateX(${
                                    table.getState().columnSizingInfo.deltaOffset
                                    }px)` : "",
                            }}
                        />
                    </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="w-auto h-7">
                    {row.getVisibleCells().map(cell => (
                    <td
                        key={cell.id}
                        className="border border-gray-300 h-7"
                        style={{
                            width: cell.column.getSize(),
                            padding: "0 6px",
                            whiteSpace: userMode==="editor"?"normal":"nowrap"                   
                        }}
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                    ))}
                </tr>
            ))}
            </tbody>
            </table>

            {/* Pagination controller */}
            <div className="flex items-center gap-2">
            <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            >
            {'<<'}
            </button>
            <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            >
            {'<'}
            </button>
            <button
            className="border rounded p-1"
            onClick={() => {table.nextPage()}}
            disabled={!table.getCanNextPage()}
            >
            {'>'}
            </button>
            <button
            className="border rounded p-1"
            onClick={() => {table.setPageIndex(table.getPageCount() - 1)}}
            disabled={!table.getCanNextPage()}
            >
            {'>>'}
            </button>
            <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
            </strong>
            </span>
            <span className="flex items-center gap-1">
            | Go to page:
            <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
                }}
                className="border p-1 rounded w-16"
            />
            </span>
            </div>

        </div>
    )
}

export default PagedIOCsTable