import {
  setLogicMonitorRecord,
  setTeamsWebhookSecureConnect,
} from "../../../redux/formDataSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import FormTitle from "../utilities/FormTitle";
import FormInput from "../utilities/FormInput";
import JiraForm from "../utilities/JiraForm";
import { setFormInvalid, setFormValid } from "../../../redux/formSlice";
import { useEffect } from "react";

const FormSecureConnect = () => {
  const dispatch = useAppDispatch();
  const currentFormData = useAppSelector((state) => state.formData);

  const validateJiraRecord = () => {
    const currentJiraRecord = currentFormData.jira_record.secure_connect;
    return (
      currentJiraRecord.organisation !== "" && currentJiraRecord.project !== ""
    );
  };

  const isFieldValidated =
    validateJiraRecord() && currentFormData.logic_monitor.company_name !== "";

  useEffect(() => {
    if (isFieldValidated) dispatch(setFormValid());
    else dispatch(setFormInvalid());
  }, [isFieldValidated]);

  return (
    <div>
      <FormTitle />
      <JiraForm product="secure_connect" />
      <FormInput
        title="Logic Monitor Company Name"
        name="logic-monitor"
        value={currentFormData.logic_monitor.company_name}
        extraStyle="font-bold"
        onChangeAction={(e) => dispatch(setLogicMonitorRecord(e.target.value))}
      />
      <FormInput
        title="Teams Webhook (Optional)"
        name="team-webhook"
        extraStyle="font-bold"
        required={false}
        value={currentFormData.teams_webhook_secure_connect}
        placeholder="The Customer MS Team Webhook to use as comms channel for notification..."
        onChangeAction={(e) =>
          dispatch(setTeamsWebhookSecureConnect(e.target.value))
        }
      />
    </div>
  );
};

export { FormSecureConnect };
