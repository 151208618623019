export function formatDateUTC(timestamp: any) {    
    if (!timestamp) {
      return "inactive";
    }
    const time = new Date(timestamp).getTime();    
    const timeNow = new Date().getTime();
    const difference = timeNow - time;
    const seconds = difference / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    if (days >= 1) {
      return `last active: ${days.toFixed(0)} days ago`;
    } else if (hours >= 1) {
      return `last active: ${hours.toFixed(0)} hours ago`;
    } else if (minutes >= 1) {
      return `last active: ${minutes.toFixed(0)} minutes ago`;
    } else {
      return `last active: ${seconds.toFixed(0)} seconds ago`;
    }
}