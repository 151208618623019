import Theme from "../../Theme";
import { EditorStyle } from "../../models/EditorModel";
import { ReportSection } from "../../models/ReportModel";
import { useAppSelector } from "../../redux/hook";
import { getFromPath, sectionNameToUpperCase } from "../../service/dataLogic";
import EditingBox from "../utility/EditingBox";
import EditingSelector from "./EditingSelector";


/**
 * Row of the entry editor. Accept single value OR dictionary for multiple columns.
 */
interface EntryRowProp {
    path: string;
    style: number
}

function EntryRow({path, style} : EntryRowProp) {
    let currentReport = useAppSelector((state) => state.data.currentReport)
    let placeholder: string | {[key:string]:string} = "Enter Here"

    let colNum = 1
    let pathList = path.split(".");
    let editorTitle = sectionNameToUpperCase(pathList[pathList.length - 1]);

    if (style === EditorStyle.DOUBLE_COL_INPUT) {
        colNum = 2
        placeholder = {"info": "Description here.", "status": "1:Healthy 2:Action Required 3:Disruption"}
    } else if (style === EditorStyle.WORKSPACE_COST_ANALYSIS) {
        colNum = 7
        placeholder = {"retention_cost": "", "ingestion_cost": "", "logic_app_cost": "", "total_cost": "", "cost_anomaly_detected": "true/false","baseline_monthly_cost": "", "workspace_name": ""}
        const section = getFromPath(currentReport!, path)
        if (section) {
            const workspaceName = section["workspace_name" as keyof ReportSection]
            const workspaceId = pathList[pathList.length - 1]
            editorTitle = sectionNameToUpperCase(workspaceName + " (" + workspaceId + ")");
        };
    }

    function generateColumns(){
        let col = [] 
        const ignoredKeys = ["workspace_name", "baseline_monthly_cost", "calc_months", "pre_avg_cost", "pre_total_cost", "cost_two_months_ago", "cost_three_months_ago"]
        if(colNum === 1 && typeof placeholder === "string") {
            return(<EditingBox placeholder={placeholder} path={path} style={style}></EditingBox>)
        } else {
            if (!currentReport) return 
        
            let section = getFromPath(currentReport,path)
            if (typeof section === "object" && section !== null && !Array.isArray(section)) {
                for (let dictKey in section) {
                    if (typeof placeholder !== "object") return;
                    if (ignoredKeys.includes(dictKey)) continue
                    if (dictKey !== "status") {
                        col.push(
                            <div key={dictKey}>
                            {colNum === 7 && <div className="text-white pl-2">{dictKey.split("_").join(" ")}:</div>}
                            <EditingBox placeholder={placeholder[dictKey]} path={path.concat(".",dictKey)} style={style}/>
                            </div>
                            )                        
                    } else {
                        col.push(<EditingSelector key={dictKey} path={path.concat(".",dictKey)}></EditingSelector>)
                    }
                }                
            }

            return(col)
        }
    }

    return(
        <div className= {`flex m-1 p-2 ${Theme.Colors.DarkWebBg} h-fit rounded-lg shadow-sm ${Theme.Colors.Shadow}`}>
            <div className=" self-center">
                <h1 className="text-white px-2 w-40">{editorTitle}:</h1>
            </div>
            <div className= {`w-full ${colNum === 7 ? "flex flex-col gap-2" : "grid grid-flow-col"}`}>
                {generateColumns()}
            </div>   
        </div>
    )
}

export default EntryRow