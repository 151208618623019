import { useAppSelector, useAppDispatch} from "../redux/hook";
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { MdOutlineErrorOutline, MdOutlineCheckCircleOutline, MdOutlineHighlightOff } from 'react-icons/md'
import { activateFullScreenPrompt } from "../redux/fullScreenPromptSlice";
import RoundButton from "./utility/RoundButton";
import Theme from "../Theme"

function FullScreenPrompt() {

    const promptType = useAppSelector(state=>state.fullScreenPrompt.promptType)
    const dispatch = useAppDispatch()

    return(
        <div>
            {
            {
                "loading": 
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <AiOutlineLoading3Quarters className='animate-spin h-24 w-24 text-slate-100 mb-6'/>
                    <h1 className="text-2xl font-medium text-ui-lime">Loading...</h1>
                </div>,
                "error":
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <MdOutlineErrorOutline className='h-24 w-24 text-ui-red mb-6'/>
                    <h1 className="text-2xl font-medium text-white mb-6">Operation failed. Check log.</h1>
                    <RoundButton text={"Confirm"} colour={`${Theme.Colors.Primary}`} onClickAction={()=>{dispatch(activateFullScreenPrompt("false"))}} extraStyle={"w-36"}></RoundButton>
                </div>,
                "weeklyUndeterminedAlerts":
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <MdOutlineErrorOutline className='h-24 w-24 text-ui-red mb-6'/>
                    <h1 className="text-2xl font-medium text-white mb-6">Reports without pending alerts cannot include undetermined classification!</h1>
                    <h1 className="text-2xl font-medium text-white mb-6">Please resolve this before proceeding. The report was not updated.</h1>
                    <RoundButton text={"Confirm"} colour={`${Theme.Colors.Primary}`} onClickAction={()=>{dispatch(activateFullScreenPrompt("false"))}} extraStyle={"w-36"}></RoundButton>
                </div>,
                "monthlyUndeterminedAlerts":
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <MdOutlineErrorOutline className='h-24 w-24 text-ui-red mb-6'/>
                    <h1 className="text-2xl font-medium text-white mb-6">Report contains alerts with an undetermined classification!</h1>
                    <h1 className="text-2xl font-medium text-white mb-6">Please resolve this before proceeding. The report was not updated.</h1>
                    <RoundButton text={"Confirm"} colour={`${Theme.Colors.Primary}`} onClickAction={()=>{dispatch(activateFullScreenPrompt("false"))}} extraStyle={"w-36"}></RoundButton>
                </div>,
                "finalised":
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <MdOutlineCheckCircleOutline className='h-24 w-24 text-ui-lime mb-6'/>
                    <h1 className="text-2xl font-medium text-white mb-6">Report Finalised!</h1>
                    <RoundButton text={"Confirm"} colour={`${Theme.Colors.Primary}`} onClickAction={()=>{dispatch(activateFullScreenPrompt("false"))}} extraStyle={"w-36"}></RoundButton>
                </div>, 
                "unfinalised":
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <MdOutlineCheckCircleOutline className='h-24 w-24 text-ui-lime mb-6'/>
                    <h1 className="text-2xl font-medium text-white mb-6">Report Unfinalised!</h1>
                    <RoundButton text={"Confirm"} colour={`${Theme.Colors.Primary}`} onClickAction={()=>{dispatch(activateFullScreenPrompt("false"))}} extraStyle={"w-36"}></RoundButton>
                </div>,                                
                "published":
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <MdOutlineCheckCircleOutline className='h-24 w-24 text-ui-lime mb-6'/>
                    <h1 className="text-2xl font-medium text-white mb-6">Report Published!</h1>
                    <RoundButton text={"Confirm"} colour={`${Theme.Colors.Primary}`} onClickAction={()=>{dispatch(activateFullScreenPrompt("false"))}} extraStyle={"w-36"}></RoundButton>
                </div>,
                "accepted":
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <MdOutlineCheckCircleOutline className='h-24 w-24 text-ui-lime mb-6'/>
                    <h1 className="text-2xl font-medium text-white mb-6">New Baseline Accepted!</h1>
                    <RoundButton text={"Confirm"} colour={`${Theme.Colors.Primary}`} onClickAction={()=>{dispatch(activateFullScreenPrompt("false"))}} extraStyle={"w-36"}></RoundButton>
                </div>,
                "rejected":
                <div className="fixed flex flex-col items-center justify-center top-0 left-0 h-screen w-screen backdrop-blur-sm bg-slate-700/60 z-10">
                    <MdOutlineHighlightOff className='h-24 w-24 text-ui-red mb-6'/>
                    <h1 className="text-2xl font-medium text-white mb-6">New Baseline Rejected!</h1>
                    <RoundButton text={"Confirm"} colour={`${Theme.Colors.Primary}`} onClickAction={()=>{dispatch(activateFullScreenPrompt("false"))}} extraStyle={"w-36"}></RoundButton>
                </div>
            }[promptType]   
            }
            
        </div>
        
    )
}

export default FullScreenPrompt