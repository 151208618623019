import { useAppSelector } from "../../../redux/hook";

const FormTitle = () => {
  const currentPageKey = useAppSelector((state) => state.form.pageKey);

  return (
    <div className="form-title">
      <span className="text-xl font-bold mb-3">{currentPageKey} Form</span>
    </div>
  );
};

export default FormTitle;
