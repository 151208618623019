import { createSlice } from "@reduxjs/toolkit";

interface FieldValueState {
  allowedServices: {
    "services": [
        { name: string, id: string }
    ]
  };
  formType: string;
  pageKey: string;
  isValidated: boolean;

}

const initialState: FieldValueState = {
   allowedServices: {
    "services": [
        { "name": "", "id": "" },
    ],
   },
   formType: "",
   pageKey: "Info",
   isValidated: false,
}

export const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        setAllowedServices: (state, action) => {
            state.allowedServices = action.payload
        },
        setFormType: (state, action) => {
            state.formType = action.payload;
        },
        resetFormType: (state) => {
            state.formType = "";
        },
        setPageKey: (state, action) => {
            state.pageKey = action.payload;
        },
        resetPageKey: (state) => {
            state.pageKey = "Info";
        },
        setFormValid: (state) => {
            state.isValidated = true;
        },
        setFormInvalid: (state) => {
            state.isValidated = false;
        },
    }
})

export const { setAllowedServices, setFormType, resetFormType, setPageKey, resetPageKey, setFormValid, setFormInvalid } = formSlice.actions

export default formSlice.reducer