import { MouseEventHandler } from "react";
import Theme from "../../Theme";

/**
 * All style settings expect FULL tailwind class name, i.e.("bg-orange-500, text-gray-50")
 */

interface RoundButtonProp {
    text: string;
    icon?: JSX.Element;
    onClickAction: MouseEventHandler<HTMLButtonElement>;
    size?: string;
    colour?: string;
    textColour?: string;
    disabled?: boolean;
    extraStyle?: string
}

function RoundButton({text, icon, onClickAction, size="text-sm",colour="bg-orange-500", textColour="text-white", disabled=false, extraStyle=""} : RoundButtonProp) {

    return (
      <div>
        <button
          onClick={onClickAction}
          disabled={disabled}
          className={`px-3 py-1 rounded-full transition ease-out duration-300 active:brightness-90 active:shadow-inner disabled:brightness-50 disabled:shadow-sm hover:brightness-110 shadow-sm ${size} ${colour} ${textColour} ${extraStyle} ${Theme.Colors.Shadow} ${
            colour === Theme.Colors.NeonGreenBg
              ? "enabled:hover:text-ui-darkweb"
              : "enabled:hover:text-ui-neonGreen"
          }`}
        >
          {icon} {text}
        </button>
      </div>
    );
}

//"px-4 py-1 rounded-full shadow-sm active:shadow-inner"+" bg-"+colour +" text-"+size +" hover:bg-"+hoverColour + " active:bg-"+pressedColour

export default RoundButton