import { BarDatum } from "@nivo/bar"
import { useAppSelector } from "../../redux/hook"
import { LineChartData } from "../report/LineChart"
import CountLineChart from "./CountLineChart"
import DailyCountBarChart from "./DailyCountBarChart"
import { SightedIOC } from "../../models/ReportModel"


const AdminMetrics = () => {
    const totalCounts = useAppSelector(state => state.sherlock.totalCounts)
    const dailyMetrics = useAppSelector(state => state.sherlock.dailyMetrics)
    const weeklyMetrics = useAppSelector(state => state.sherlock.weeklyMetrics)
    const lastWeekIOCsRank = useAppSelector(state => state.sherlock.lastWeekIOCsRank)
    const lastMonthIOCsRank = useAppSelector(state => state.sherlock.lastMonthIOCsRank)
    const formattedWeeklyCounts: LineChartData[] = [{"id": "Count", "data": []}]
    const formattedDailyCounts: BarDatum[] = []
    
    weeklyMetrics?.forEach((metric) => {
        const entry = {x: metric.id, y: 0}
        let count = 0
        metric.daily_counts.forEach(item => {
            count += Object.values(item)[0]
        })
        entry.y = count
        formattedWeeklyCounts[0].data.unshift(entry)
    })

    dailyMetrics?.forEach((metric) => {
        formattedDailyCounts.push({
            "customer": Object.keys(metric)[0],
            "count": Object.values(metric)[0]
        })
    })

    const renderIOCsRank = (ranks: SightedIOC[]) => {
        return (
            <div>
                <table className="w-full border-separate border-spacing-1">
                    <thead className="bg-gray-500">
                        <tr>
                            <th className="rounded-sm text-sm text-white font-medium">INDICATOR</th>
                            <th className="rounded-sm text-sm text-white font-medium">COUNT</th>
                        </tr>
                    </thead>
                    <tbody>                
                    {ranks.map((item, i) => {
                        return (
                        <tr className="bg-gray-100" key={i}>
                            <td className="rounded-sm text-sm">{atob(item.indicator)}</td>
                            <td className="rounded-sm text-sm">{item.count}</td>
                        </tr>
                        )
                    })}
                    </tbody>
                </table>            
           </div>            
        )
    }

    return (
        <div className={`w-[90%] max-w-screen-2xl mt-5 bg-white bg-opacity-90`}>
            <div className="mt-6">
            {
                totalCounts === null ?
                <div>Loading total counts...</div>
                :
                <div className="flex flex-row justify-between">
                    <div className="ml-6">Total Sightings: {totalCounts?.sightings_count}</div>
                    <div className="mr-6">Total Nonexpired Indicators: {totalCounts?.indicators_count}</div>                
                </div>                
            }                
            </div>

            <div className="mt-6">
            {
                dailyMetrics.length === 0 ?
                <div>Loading daily metrics...</div>
                :
                <div className="h-[400px]">
                    <div className="ml-6">Today:</div>
                    <DailyCountBarChart data={formattedDailyCounts} />
                </div>                
            }                
            </div>

            <div className="mt-6">
            {
                weeklyMetrics.length === 0 ?
                <div>Loading weekly metrics...</div>
                :
                <div className="h-[400px]">
                    <div className="ml-6">Last 7 days:</div>
                    <CountLineChart data={formattedWeeklyCounts} />                
                </div>                
            }                
            </div>

            <div className="mt-6">
            {
                lastWeekIOCsRank.length !== 0 &&
                <div className="">
                    <div className="ml-6">IOCs rank - last week:</div>
                    {renderIOCsRank(lastWeekIOCsRank)}             
                </div>                
            }                
            </div> 

            <div className="mt-6">
            {
                lastMonthIOCsRank.length !== 0 &&
                <div className="">
                    <div className="ml-6">IOCs rank - last month:</div>
                    {renderIOCsRank(lastMonthIOCsRank)}             
                </div>                
            }                
            </div>            
        </div>
    )
}

export default AdminMetrics