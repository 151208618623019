import { MultiPageReportContentStyle, MultiPageReportPageStyle, MultiPageReportTableTitle, MultiPageReportTitle, MultiPageTableTextStyle } from "../../models/StyleModel"
import { useAppSelector } from "../../redux/hook"
import { countTicketsByBreachedStatus, getFromPath, parseTicketsToLineChartData, sectionNameToUpperCase } from "../../service/dataLogic"
import Logo from "../../resource/DEFENDLogo-Black.png"
import { Content, DocumentHistory, FirewallResourceAvailability, IncidentResolutionTarget, InterfaceUtilization, ServiceRequestTarget, SoftwareLicensingStatus, SoftwareStatus, SystemResources, Ticket } from "../../models/ReportModel"
import LineChart from "../report/LineChart"
import { useEffect, useState } from "react"
import Theme from "../../Theme"

const SCMonthlyReport = () => {
    const currentReport = useAppSelector((state) => state.data.currentReport)
    const [contents, setContents] = useState<Content[]>([])
    const MAX_ROWS_IN_PAGE = 44

    useEffect(() => {
        const reportContainer = document.getElementById("ReportContainer")
        if (!reportContainer) return 

        const pageArr = reportContainer.querySelectorAll(":scope > div")
        const pageContents:Content[] = []
        for (let i = 0; i < pageArr.length; i++) {
            const pageDiv = pageArr[i]
            if (pageDiv.id) {
                pageContents.push({title:pageDiv.id,pageNum: i+1})
            }
        }

        setContents(pageContents)
    },[])

    const renderFrontPage = () => {
        if (currentReport === null) return 
        const companyName = getFromPath(currentReport,"content.company_name") as string
        
        return (
            <div className={`${MultiPageReportPageStyle}`}>
                <div className={`aspect-w-2 aspect-h-3 grid grid-rows-2`}>
                    <div className="relative w-[80%] my-40 mx-auto font-bold text-center text-7xl text-ui-lime">Secure Connect Monthly Report - {companyName}</div>
                    <img className="relative w-[300px] h-[300px] mx-auto" src={Logo} alt="Defend Logo in secure connect report" />                    
                </div>
            </div>
        )
    }

    const renderDocDetails = () => {
        if (currentReport === null) return 
        const tableArr = getFromPath(currentReport, "content.general.document_history") as unknown as DocumentHistory[]

        return (
            <div className={`relative`}>
                <div className={`${MultiPageReportTitle}`}>Document Details</div>
                <div className={`${MultiPageReportTableTitle}`}>Document History</div>
                {renderTable(tableArr)}
            </div>
        )       
    }

    const renderContents = () => {

        return (
            <div className={`relative flex flex-col gap-1`}>
                <div className={`${MultiPageReportTitle}`}>Contents</div>
                {contents.map((content, index) => (
                    <div key={`${index}`} className={`flex flex-row flex-nowrap`}>
                        <a href={`#${content.title !== "Software Status" ? content.title:"st_anchor"}`}>
                            <div className={`text-neutral-900`}>{content.title}</div>
                        </a>  
                        <div  className={`flex-auto mb-1 border-dotted border-b-2 border-neutral-900`}></div> 
                        <div className={`text-neutral-900`}>{content.pageNum}</div>                   
                    </div>
                ))}
            </div>            
        )
    }

    const renderExecutiveSummary = () => {
        if (currentReport === null) return 
        const summary = getFromPath(currentReport, "content.general.summary") as string
        
        return (
            <div id="Executive Summary" className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle}`}>
                    <div className={`${MultiPageReportTitle} relative`}>Executive Summary</div>
                    <div className={`text-neutral-900 relative`}>{summary}</div>                    
                </div>
            </div>
        )
    }

    const renderRequestsAndIncidents = () => {
        if (currentReport === null) return 
        const summary = getFromPath(currentReport, "content.general.service_requests_and_incidents_summary") as string
        const incidentTargetsArr = getFromPath(currentReport, "content.general.incident_resolution_targets") as unknown as IncidentResolutionTarget[]
        const requestTargetsArr = getFromPath(currentReport, "content.general.service_request_targets") as unknown as ServiceRequestTarget[]

        return (
            <div id="Service Requests and Incidents" className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle}`}>
                    <div className={`${MultiPageReportTitle} relative`}>Service Requests and Incidents</div>
                    <div className={`mt-1 text-neutral-900 relative`}>{summary}</div>
                    <div className={`mt-6 text-neutral-900 relative`}>SLAs for this service are:</div>
                    <div className={`mt-4 ${MultiPageReportTableTitle} relative`}>Incident Resolution Targets</div>
                    <div className={`text-sm text-neutral-900 relative`}>System alerts will be categorised as events which are analysed and tuned, or incidents raised which will be managed based on defined SLA's.</div>
                    <div className={`mt-1 text-sm text-neutral-900 relative`}>Response Targets are defined in the following table:</div>
                    <div className="my-2 relative">{renderTable(incidentTargetsArr)}</div>
                    <div className={`mt-1 text-sm text-neutral-900 relative`}>Resolution Time: means the period elapsed from the time you log the fault to the time that the affected Services is restored to normal operation.</div>
                    <div className={`mt-4 ${MultiPageReportTableTitle} relative`}>Service Request Targets</div>
                    <div className="my-2 relative">{renderTable(requestTargetsArr)}</div>
                </div>
            </div>
        )
    }

    const renderSLAReport = () => {
        if (currentReport === null) return 
        const tickets = getFromPath(currentReport, "content.tickets.open_tickets") as unknown as Ticket[]
        const endDate = getFromPath(currentReport, "content.end_date") as string

        const countObj = countTicketsByBreachedStatus(tickets)
        const chartArr = parseTicketsToLineChartData(tickets, endDate)

        return (
            <div className={`relative h-1/2`}>
                <div className={`${MultiPageReportTableTitle}`}>SLA Report</div>
                <div className="my-1 grid grid-cols-2">
                    <div className="col-span-1 flex flex-col">
                        <div className={`text-ui-lime text-center`}>{countObj.met}</div>
                        <div className={`text-neutral-900 text-sm text-center`}>{"Time to First Response (Met)"}</div>                        
                    </div>
                    <div className="col-span-1 flex flex-col">
                        <div className={`text-red-500 text-center`}>{countObj.breached}</div>
                        <div className={`text-neutral-900 text-sm text-center`}>{"Time to First Response (Breached)"}</div>                        
                    </div>
                </div>
                <div className="h-[400px] bg-gray-100"><LineChart data={chartArr} /></div>
            </div>
        )
    }

    const renderSoftwareStatus = () => { 
        if (currentReport === null) return 
        const tableArr = getFromPath(currentReport, "content.logic_monitor.software_status") as unknown as SoftwareStatus[]

        return (
            <div id="st_anchor" className="relative">
                <div className={`${MultiPageReportTitle}`}>Software Status</div>
                {renderTable(tableArr)}
            </div>
        )
    }

    const renderLicensingStatus = () => {
        if (currentReport === null) return 
        const tableArr = getFromPath(currentReport, "content.logic_monitor.software_licenses") as unknown as SoftwareLicensingStatus[]
        let finalArr = []

        if (tableArr.length > MAX_ROWS_IN_PAGE) {
            const arrNum = Math.ceil(tableArr.length/MAX_ROWS_IN_PAGE)
            const firstArr = tableArr.slice(0,MAX_ROWS_IN_PAGE)
            finalArr.push(firstArr)

            for (let i = 1; i < arrNum; i++) {
                const extraArr:SoftwareLicensingStatus[] = tableArr.slice(MAX_ROWS_IN_PAGE*i,MAX_ROWS_IN_PAGE*(i+1))
                finalArr.push(extraArr)                 
            }
        } else {
            finalArr.push(tableArr)
        }

        return finalArr.map((arr,index) => {
            if (index === 0) {
                return (
                    <div id="Licensing Status" key={`${index}`} className={`${MultiPageReportPageStyle}`}>
                        <div className={`${MultiPageReportContentStyle}`}>
                            <div className={`${MultiPageReportTitle} relative`}>Licensing Status</div>
                            {renderTable(tableArr)}
                        </div>
                    </div>
                )                  
            } else {
                return (
                    <div key={`${index}`} className={`${MultiPageReportPageStyle}`}>
                        <div className={`${MultiPageReportContentStyle}`}>
                            {renderTable(arr)}                    
                        </div>
                    </div>                       
                )
            }
        })
      
    }

    const renderFirewallMonitoring = () => {
        if (currentReport === null) return 
        const summary = getFromPath(currentReport, "content.general.firewall_availability_summary") as string
        const tableArr = getFromPath(currentReport, "content.logic_monitor.firewall_resource_availability") as unknown as FirewallResourceAvailability[]

        return (
            <div id="Availability - Managed Firewall" className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle}`}>
                    <div className={`${MultiPageReportTitle} relative`}>{"Availability - Managed Firewall"}</div>
                    <div className={`text-neutral-900 relative`}>{summary}</div>
                    <div className={`mt-4 ${MultiPageReportTableTitle} relative`}>{"Firewall Monitoring - (Ping to Firewalls from Collectors)"}</div>
                    {renderTable(tableArr)}                    
                </div>

            </div>
        )
    }

    const renderInterfaceUtilization = () => {
        if (currentReport === null) return 
        const tableArr = getFromPath(currentReport, "content.logic_monitor.interface_utilization") as unknown as InterfaceUtilization[]

        return (
            <div className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle}`}>
                    <div className={`${MultiPageReportTableTitle} relative`}>{"Interface Utilization - (Top 10 Interfaces)"}</div>
                    <div className="ml-[-30px] relative">
                        {renderTable(tableArr)}
                    </div>               
                </div>
            </div>
        )
    }

    const renderSystemResources = () => {
        if (currentReport === null) return 
        const tableArr = getFromPath(currentReport, "content.logic_monitor.system_resources") as unknown as SystemResources[]
        let finalArr = []

        
        if (tableArr.length > MAX_ROWS_IN_PAGE) {
            const arrNum = Math.ceil(tableArr.length/MAX_ROWS_IN_PAGE)
            const firstArr = tableArr.slice(0,MAX_ROWS_IN_PAGE)
            finalArr.push(firstArr)

            for (let i = 1; i < arrNum; i++) {
                const extraArr:SystemResources[] = tableArr.slice(MAX_ROWS_IN_PAGE*i,MAX_ROWS_IN_PAGE*(i+1))
                finalArr.push(extraArr)                 
            }
        } else {
            finalArr.push(tableArr)
        }

        return finalArr.map((arr,index) => {
            if (index === 0) {
                return (
                    <div key={`${index}`} className={`${MultiPageReportPageStyle}`}>
                        <div className={`${MultiPageReportContentStyle}`}>
                            <div className={`${MultiPageReportTableTitle} relative`}>{"System Resources: CPU, Disk, Memory (TOP 10)"}</div>
                            {renderTable(arr)}                    
                        </div>
                    </div>                    
                )
            } else {
                return (
                    <div key={`${index}`} className={`${MultiPageReportPageStyle}`}>
                        <div className={`${MultiPageReportContentStyle}`}>
                            {renderTable(arr)}                    
                        </div>
                    </div>                      
                )
            }
        })
    }

    const renderBestPractice = () => {
        if (currentReport === null) return 
        const practice = getFromPath(currentReport, "content.general.best_practice") as string

        return (
            <div id="Alignment to Industry Best Practice - Managed Firewall" className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle}`}>
                    <div className={`${MultiPageReportTitle} relative`}>{"Alignment to Industry Best Practice - Managed Firewall"}</div>
                    <div className={`text-neutral-900 relative`}>{practice}</div>                    
                </div>

            </div>
        )
    }

    const renderServicesAndIncidentRequests = () => {
        if (currentReport === null) return 
        const tickets = getFromPath(currentReport, "content.tickets.open_tickets") as unknown as Ticket[]

        return (
            <div id="Active Service and Incident Requests" className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle}`}>
                    <div className={`${MultiPageReportTitle} relative`}>{"Active Service and Incident Requests"}</div>
                    <div className={`text-neutral-900 my-1 relative`}>{"The tickets below have been logged by DEFEND and are tidy up activities post transition to DEFEND support."}</div>
                    <table className={`w-full border-spacing-1 relative`}>
                        <thead className={`${Theme.Colors.Primary}`}>
                            <tr>
                                <th className={`${MultiPageTableTextStyle}`}>Imporovement</th>
                                <th className={`${MultiPageTableTextStyle}`}>Reason</th>
                                <th className={`${MultiPageTableTextStyle}`}>Status</th>
                                <th className={`${MultiPageTableTextStyle}`}>Ticket Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map((ticket, index) => {
                                let rowColor:string = ""
                                if (index % 2 === 0) {rowColor = "bg-gray-300"}
                                else {rowColor = "bg-gray-100"}

                                return (<tr className={`${rowColor}`} key={index}>
                                    <td className={`${MultiPageTableTextStyle}`}>{ticket.description}</td>
                                    <td className={`${MultiPageTableTextStyle}`}>{ticket.summary}</td>
                                    <td className={`${MultiPageTableTextStyle}`}>{ticket.status}</td>
                                    <td className={`${MultiPageTableTextStyle}`}>{ticket.ticket_key}</td>                                    
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const renderContact = () => {
        return (
            <div className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle}`}>
                    <div className={`${MultiPageReportTitle} relative`}>Contact</div>
                    <div className={`text-neutral-900 relative`}>If you need any more information regarding this report, please contact:</div>
                    <a className={`text-ui-lime underline relative`} href="mailto: noc@defend.co.nz">noc@defend.co.nz</a>
                </div>
            </div>            
        )
    }

    const renderTable = (tableArr: SoftwareStatus[]|SoftwareLicensingStatus[]|FirewallResourceAvailability[]|InterfaceUtilization[]|SystemResources[]|IncidentResolutionTarget[]|ServiceRequestTarget[]|DocumentHistory[]) => {
        if (tableArr.length === 0) return 

        const tableHead = Object.keys(tableArr[0])

        return (
            <table className={`w-full border-spacing-1 relative`}>
                <thead className={`${Theme.Colors.Primary}`}>
                    <tr>
                        {tableHead.map((cellName,index) => {
                            cellName = sectionNameToUpperCase(cellName)
                            return (<th className={`${MultiPageTableTextStyle}`} key={index}>{cellName}</th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {tableArr.map((row,index) => {
                        let rowColor:string = ""
                        if (index % 2 === 0) {rowColor = "bg-gray-300"}
                        else {rowColor = "bg-gray-100"}

                        return (
                            <tr className={`${rowColor}`} key={index}>
                                {Object.entries(row).map(([cell,cellValue]) => {
                                    if (cellValue.length === 0) cellValue = "-"

                                    return (<td className={`${MultiPageTableTextStyle}`} key={cell}>{cellValue}</td>)
                                })}
                            </tr>
                        )
                    })}                            
                </tbody>
            </table>
        )
    }

    return (
        <div id="ReportContainer" className={`p-3 w-full h-full overflow-clip`}>
            {renderFrontPage()}
            <div className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle} grid grid-rows-2 gap-20`}>
                    {renderDocDetails()}
                    {renderContents()}                      
                </div>
            </div>

            {renderExecutiveSummary()}
            {renderRequestsAndIncidents()}

            <div id="Software Status" className={`${MultiPageReportPageStyle}`}>
                <div className={`${MultiPageReportContentStyle} grid grid-rows-2 gap-20`}>
                    {renderSLAReport()}
                    {renderSoftwareStatus()}                     
                </div>  
            </div>

            {renderLicensingStatus()}
            {renderFirewallMonitoring()}
            {renderInterfaceUtilization()}
            {renderSystemResources()}
            {renderBestPractice()}
            {renderServicesAndIncidentRequests()}
            {renderContact()}
        </div>
    )
}

export default SCMonthlyReport