import Theme from "../../Theme"

const HealthBadge = ({status}:{status:number}) => {
    let color = `${Theme.Colors.Primary}`
    if (status === 2) { 
        color = "bg-orange-500"
    } else if ( status === 3) {
        color = "bg-red-500"
    }

    return (
        <div className="">
            <div className={`${color} rounded-full w-[10px] h-[10px]`} />
        </div>
    )
}

export default HealthBadge