import { EditorPageStyle } from "../../models/StyleModel"
import PageNotAccessable from "../../pages/PageNotAccessable"
import BaselineDisplay from "./BaselineDisplay"
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { useEffect } from "react"
import { formatDateUTC } from "../../utility/DateHelpers";
import { BaselineDifferenceEntry, BaselineDifferenceObj, CommonBaselineEntry, DataConnectorEntry } from "../../models/BaselineModel"
import Theme from "../../Theme"
import { MdOutlineCheckCircleOutline, MdOutlineHighlightOff } from "react-icons/md"
import { compareCommonTypes, compareDataConnectors } from "../../service/dataLogic"
import { setCurrentSentinelWorkspace, setDisplayBaselineDifferences, setHistoricalBaselineIds } from "../../redux/dataSlice"
import { getBauBaselines } from "../../service/apiAccessLogic"
import { msalInstance } from "../.."

const BaselineView = () => {
  let titleStyle = "text-base font-medium text-center flex flex-col my-3";
  const userMode = useAppSelector((state) => state.user.userMode)
  const displayBaselineDifferences = useAppSelector((state) => state.data.displayBaselineDifferences);
  const allCustomersID = useAppSelector((state) => state.data.allCustomersID);
  const customerId = useAppSelector((state) => state.data.currentCustomerId);
  const currentSentinelWorkspace = useAppSelector((state) => state.data.currentSentinelWorkspace);
  const historicalBaselineIds = useAppSelector((state) => state.data.historicalBaselineIds);
  const currentHistoricalBaseline = useAppSelector((state) => state.data.currentHistoricalBaseline);
  const currentAcceptedBaseline = useAppSelector((state) => state.data.currentAcceptedBaseline);
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    const hasHistoricalBaselines = historicalBaselineIds && Object.entries(historicalBaselineIds).length > 0;
    const historicalBaselineSelected = currentHistoricalBaseline && Object.entries(currentHistoricalBaseline).length > 0;
    if (hasHistoricalBaselines || historicalBaselineSelected) return
    dispatch(setDisplayBaselineDifferences(false));
  }, [historicalBaselineIds, currentHistoricalBaseline, dispatch])

  useEffect(() => {
    // setting the historical baseline Ids for the selected customers
    if (!customerId || currentSentinelWorkspace === "") return;
    if (historicalBaselineIds) return;
    getBauBaselines(msalInstance, "historical_ids", "", customerId as string, currentSentinelWorkspace)
    .then((response) => {
        if (response.status === 200) { dispatch(setHistoricalBaselineIds(response.data)) }
      })
      .catch(() => {
        console.log("Error loading historical baseline ids");
      });
  }, [customerId, currentSentinelWorkspace, dispatch]);

  useEffect(() => {
    if (currentAcceptedBaseline) {
      const workspace = currentAcceptedBaseline.workspace;
      if (!workspace || currentSentinelWorkspace !== "") return;
      dispatch(setCurrentSentinelWorkspace(workspace));
    }
  }, [currentAcceptedBaseline, dispatch]);
  
  const renderBaselinesComponent = () => {
    const isValidCustomer = allCustomersID.includes(customerId as string);

    if (!isValidCustomer) {
      return (
        <div className={`${EditorPageStyle} w-full h-60 flex flex-col justify-center items-center gap-y-5`}>
          <h1 className={`text-white text-xl font-medium w-fit px-6 py-1.5`}>
            The requested customer doesn't exist
          </h1>
          <h1 className={`text-white text-xl font-medium w-fit px-6 py-1.5`}>
            Please select customer from left menu
          </h1>
        </div>
      );
    }

    if (!currentAcceptedBaseline || Object.entries(currentAcceptedBaseline).length === 0) {
      return (
        <div className={`${EditorPageStyle} w-full h-60 flex flex-col justify-center items-center`}>
          <h1 className={`text-white text-xl font-medium w-fit px-6 py-1.5`}>
            No baseline available for this customer
          </h1>
        </div>
      );
    }

    if (currentAcceptedBaseline && Object.entries(currentAcceptedBaseline).length > 0 && displayBaselineDifferences) {
      if ((currentHistoricalBaseline && Object.entries(currentHistoricalBaseline).length > 0) || (historicalBaselineIds && Object.entries(historicalBaselineIds).length > 0 && !currentHistoricalBaseline)) {
        let latestBaseline = {};
        if (!currentHistoricalBaseline || Object.entries(currentHistoricalBaseline).length === 0) {
          latestBaseline = {
            "Logic Apps": [],
            "Workbooks": [],
            "Automation Rules": [],
            "Watch Lists": [],
            "Alert Rules": [],
            "Hunting Queries": [],
            "Data Connectors": [],
            accepted: false,
            backup_container_name: "",
            id: "",
            baseline_id: "",
            customer_id: "",
          };
        } else {
          latestBaseline = currentHistoricalBaseline;
        }

        let dataConnectorDifferences: BaselineDifferenceObj[] = [];
        let commonDifferences: BaselineDifferenceObj[] = [];

        Object.entries(latestBaseline).forEach(([key, value]) => {
          if (key === "Data Connectors") {
            let currentDataConnectorEntry = value as unknown as DataConnectorEntry[];
            let newDataConnectorEntry = currentAcceptedBaseline["Data Connectors"];
            dataConnectorDifferences.push(
              compareDataConnectors(
                currentDataConnectorEntry,
                newDataConnectorEntry
              )
            );
          }

          if (
            key === "Watch Lists" ||
            key === "Automation Rules" ||
            key === "Alert Rules" ||
            key === "Logic Apps" ||
            key === "Workbooks" ||
            key === "Hunting Queries"
          ) {
            let currentEntryArray = value as unknown as CommonBaselineEntry[];
            let newEntryArray = currentAcceptedBaseline[key];
            commonDifferences.push(
              compareCommonTypes(key, currentEntryArray, newEntryArray)
            );
          }
        });
        return (
          <div className={`${EditorPageStyle}`}>
            <div className="text-base font-medium text-center text-white">
              <div className={`${titleStyle}`}>ENVIRONMENT CHANGES</div>
              <div className={`${titleStyle}`}>Sentinel Baseline for {currentHistoricalBaseline?.date}</div>
              <div className="flex justify-center text-sm mb-3">Backup file: {currentHistoricalBaseline?.backup_container_name}</div>
            </div>
            <div className="flex flex-row gap-2 text-sm text-white">
              <div>Legend:</div>
              <div className={`${Theme.Colors.Primary} flex flex-row`}>
                <MdOutlineCheckCircleOutline className="h-5 w-5" />: Enabled
              </div>
              <div className={`${Theme.Colors.Error} flex flex-row`}>
                <MdOutlineHighlightOff className="h-5 w-5" />: Disabled
              </div>
            </div>
            {renderBaselineDifferences("common", commonDifferences)}
            <div className={`w-full p-1 my-2 text-white font-light text-sm rounded-xl shadow-sm ${Theme.Colors.DarkWebBg}`}>
              <div className="text-base font-medium text-center">Data Connectors</div>
              <div>{renderBaselineDifferences("dataConnector", dataConnectorDifferences)}</div>
            </div>
          </div>
        );
      }
    }
  }

  const renderBaselineDifferences = (style: string, differences: BaselineDifferenceObj[]) => {
    let entryStyle = `w-full p-1 my-2 text-white font-light text-sm rounded-xl shadow-sm ${Theme.Colors.DarkWebBg}`
    let titleStyle = "text-base font-medium text-center"
    if (style === "dataConnector") {
      titleStyle = `font-normal text-center`
      entryStyle = ``
    }

    return (
      <div>
        {differences.map((difference, index) => {
          return (
            <div key={index}>
              {Object.entries(difference).map(([key, value]) => {
                return (
                  <div key={key} className={`${entryStyle}`}>
                    <div className={`${titleStyle}`}>{key}</div>
                    {renderEntries(value)}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  const renderEntries = (entries: BaselineDifferenceEntry) => {
    return (
      <div>
        {Object.entries(entries)
          .filter(([_, value]) => value.length > 0)
          .map(([key, value]) => {
            return (
              <div key={key} className={`flex flex-col gap-2 py-1`}>
                <table className={`mx-2`}>
                  <thead>
                    <tr>
                      <th className={`text-left text-sm font-light text-white`}>
                        {key.slice(0, 1).toUpperCase() + key.slice(1, key.indexOf("Entry")) + " Entry"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.map((element: CommonBaselineEntry, index: number) => {
                        let bgColor = `${Theme.Colors.Primary}`
                        let statusIcon = <MdOutlineCheckCircleOutline className="h-5 w-5 text-white" />
                        if (!element.enabled) {
                          bgColor = Theme.Colors.Error
                          statusIcon = <MdOutlineHighlightOff className="h-5 w-5 text-white" />
                        }

                        return (
                          <tr key={index} className={`${bgColor} border border-slate-50 w-full flex flex-row justify-between items-center`}>
                            <td className={`text-sm flex flex-row`}>
                              {statusIcon} {element.name}
                            </td>
                            <td className={`text-sm flex flex-row`}>
                              {element.last_active_data && formatDateUTC(element.last_active_data)}
                            </td>
                          </tr>
                        )
                    })}
                  </tbody>
                </table>
              </div>
            )
          })}
      </div>
    )
  }

  if (userMode === "editor") {
    return (
      <div className={`w-full flex min-h-screen ${
          displayBaselineDifferences && currentAcceptedBaseline && currentHistoricalBaseline
            ? "flex-row justify-center gap-10"
            : "flex-col items-center"
        }`}
      >        
      <BaselineDisplay />
        <div className={`w-[90%] max-w-screen-2xl mt-5 mb-5 shadow-2xl`}>
          {renderBaselinesComponent()}
        </div>
      </div>
    );
  } else {
    return <PageNotAccessable />
  }
}

export default BaselineView