import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { checkForEditInSection, updateData} from "../../redux/dataSlice"
import { useEffect, useState } from "react"
import { getFromPath } from "../../service/dataLogic"
import { OPTIMISATION_ACTIVITY_STATUS, SENTINEL_HEALTH_STATUS } from "../../models/EditorModel"

const EditingSelector = ({path}: {path: string}) => {
    const dispatch = useAppDispatch()
    const currentReport = useAppSelector((state) => state.data.currentReport)
    const userRoles = useAppSelector(state => state.user.userRoles)
    const [sectionValue, setSectionValue] = useState<number>(0)
    const selectorTitle = path.includes("microsoft_sentinel_health") ? "-Select Health Status-" :
                            path.includes("optimisation_activity") ? "-Select Activity Status-" : ""

    let pathToSection = path.substring(0, path.lastIndexOf("."))
    pathToSection = pathToSection.substring(0, pathToSection.lastIndexOf("."))

    const updateSection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let value: string | number = event.target.value

        if (!isNaN(Number(value))) {
            value = parseInt(value)
            setSectionValue(value)
            dispatch(updateData({'newValue':value,'path':path}))
        }
        dispatch(checkForEditInSection(pathToSection))
    }

    const generateSelectorOption = () => {
        let options = []
        let healthObj

        if (path.includes("microsoft_sentinel_health")) {
            healthObj = SENTINEL_HEALTH_STATUS
        } else if (path.includes("optimisation_activity")) {
            healthObj = OPTIMISATION_ACTIVITY_STATUS
        }

        for (let status in healthObj) {
            const key = status as unknown as keyof typeof healthObj
            options.push(<option key={status} value={status} 
                disabled={userRoles.includes("read_only") || (currentReport !== null && currentReport["finalised"] !== undefined && currentReport["finalised"] === true)}>
                {status}: {healthObj[key]}
            </option>)
        }
        
        return options
    }

    useEffect(() => {
        if (!currentReport) return  
        const sectionValue = getFromPath(currentReport,path)

        if (typeof sectionValue === "number") {
            setSectionValue(sectionValue)            
        }
    },[currentReport,path])

    return (
        <div className={`p-1`}>
            <select className= {`bg-white w-full shadow-inner text-ui-darkweb px-3 py-2 rounded-md`} 
                value={sectionValue} onChange={ updateSection }>
                <option value={0} disabled={true}>{selectorTitle}</option>
                { generateSelectorOption() }
            </select>
        </div>
    )
}

export default EditingSelector