import { LineChartData } from "./LineChart"
import { CustomLayerProps, ResponsiveLine } from "@nivo/line"
import {ScaleLinear} from "d3-scale"

const CustomGrid: React.FC<CustomLayerProps> = ({xScale}) => {
    // hightlight business hours
    const start = (xScale as ScaleLinear<number, number>)(8.5)
    const end = (xScale as ScaleLinear<number, number>)(17)
    return (
        <g>
            <line 
                x1={start}
                x2={start}
                y1={0}
                y2="70%"
                stroke="rgb(249 115 22)"
                strokeDasharray="4 4"
            />
            <line 
                x1={end}
                x2={end}
                y1={0}
                y2="70%"
                stroke="rgb(249 115 22)"
                strokeDasharray="4 4"            
            />
        </g>
    )
}

const LineChartForTicketByHours = ({data}:{data:LineChartData[]}) => {
    const ticketValues = data[0].data
        .filter((_,index) => index % 2 === 0)
        .map(data => data.x)

    return (
        <ResponsiveLine
        data={data}
        colors={{ datum: "color" }}
        margin={{ top: 20, right: 20, bottom: 50, left: 40 }}
        xScale={{
            type: 'linear',
            min: 0,
            max: 23
        }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat="> .0d"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: ticketValues,
            legend: 'Hour',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        lineWidth={2}
        pointSize={4}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor='#93b753'
        pointLabelYOffset={-12}
        useMesh={true}
        enableGridX={false}
        enableGridY={true}
        layers={['grid', (props) => <CustomGrid {...props}/>, 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
    />)
}

export default LineChartForTicketByHours