import { ResponsivePie } from "@nivo/pie"

export interface PieChartData {
    id:string;
    label:string;
    value:number;
    color?:string
}

const PieChartColorFromData = ({data, dataType}:{data:PieChartData[], dataType: string}) => {
    const isAllZeros = data.every(entry => entry.value === 0)
    let displayData = data

    if (isAllZeros) {
        displayData = [{id: `No ${dataType}`, label: `No ${dataType}`, value: 1, color: "rgb(209 213 219)"}]
    }

    return (
    <ResponsivePie
        data={displayData}
        margin={{ top: 20, right: 10, bottom: 20, left: 90 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ datum: "data.color" }}
        enableArcLabels={!isAllZeros}
        arcLabel={(data) => (data.value !== 0 ? `${data.value}` : "")}
        enableArcLinkLabels={false}
        legends={[
            {
                anchor: 'left',
                direction: 'column',
                justify: false,
                translateX: -80,
                translateY: 0,
                itemsSpacing: 0,
                itemWidth: 80,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 9,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />)
}

export default PieChartColorFromData