import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { setPageKey } from "../../../redux/formSlice";
import { MdOutlineClose } from "react-icons/md";

const CloseBtn = () => {
  return (
    <button
      type="button"
      className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
    >
      <MdOutlineClose size={20} />
    </button>
  );
};

interface NextBtnProps {
  onClickAction?: () => Promise<boolean>;
}
const NextBtn = ({ onClickAction }: NextBtnProps) => {
  const dispatch = useAppDispatch();

  const currentValidationState = useAppSelector(
    (state) => state.form.isValidated
  );
  const currentSelectedProducts = useAppSelector(
    (state) => state.formData.selected_products
  );
  const currentPageKey = useAppSelector((state) => state.form.pageKey);

  const handleNextPage = () => {
    const currentPageKeyIndex = currentSelectedProducts.indexOf(currentPageKey);
    const nextPageKey = currentSelectedProducts[currentPageKeyIndex + 1];
    dispatch(setPageKey(nextPageKey || currentSelectedProducts[0]));
  };

  const handleApiCallAndNextPage = () => {
    if (onClickAction) {
      onClickAction();
      handleNextPage();
    }
  };

  const DisabledStyle =
    "cursor-not-allowed border border-transparent focus:outline-none py-2 px-5 rounded-lg text-center text-white bg-gray-300";
  const EnabledStyle =
    "border border-transparent focus:outline-none py-2 px-5 rounded-lg text-center text-white bg-indigo-600 hover:bg-indigo-700";
  return (
    <button
      type="button"
      id="nextBtn"
      className={currentValidationState ? EnabledStyle : DisabledStyle}
      disabled={!currentValidationState}
      onClick={onClickAction ? handleApiCallAndNextPage : handleNextPage}
    >
      Next
    </button>
  );
};

const PrevBtn = () => {
  const dispatch = useAppDispatch();

  const currentSelectedProducts = useAppSelector(
    (state) => state.formData.selected_products
  );
  const currentPageKey = useAppSelector((state) => state.form.pageKey);

  const handlePrevPage = () => {
    const currentPageKeyIndex = currentSelectedProducts.indexOf(currentPageKey);
    const prevPageKey = currentSelectedProducts[currentPageKeyIndex - 1];
    dispatch(setPageKey(prevPageKey || "Info"));
  };
  return (
    <button
      type="button"
      id="prevBtn"
      className="focus:outline-none border border-gray-300 py-2 px-5 rounded-lg shadow-sm text-center text-gray-700 bg-white hover:bg-gray-100"
      onClick={handlePrevPage}
    >
      Previous
    </button>
  );
};

const FinishBtn = () => {
  const currentValidationState = useAppSelector(
    (state) => state.form.isValidated
  );

  const DisabledStyle =
    "cursor-not-allowed border border-transparent focus:outline-none py-2 px-5 rounded-lg text-center text-white bg-gray-300";
  const EnabledStyle =
    "border-transparent focus:outline-none py-2 px-5 rounded-lg text-center text-white bg-green-600 hover:bg-green-700";
  return (
    // CANNOT USE AN ONCLICK EVENT HANDLER ON THE BUTTON OF TYPE SUBMIT AS IT WILL OVERRIDE THE FORM'S SUBMIT EVENT
    <button
      type="submit"
      id="finishBtn"
      className={currentValidationState ? EnabledStyle : DisabledStyle}
      disabled={!currentValidationState}
    >
      Finish
    </button>
  );
};

export { CloseBtn, NextBtn, PrevBtn, FinishBtn };
