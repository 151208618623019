import Theme from "../Theme"

export const EditorPageStyle = `p-3 bg-white bg-opacity-50`

export const EditorCardBGStyle = `rounded-md w-full ${Theme.Colors.DarkWebBg} shadow-sm ${Theme.Colors.Shadow}`

export const ReportPageStyle = "p-3 bg-gray-300"

export const MultiPageReportPageStyle = "w-full max-h-[1200px] my-2 p-3 bg-white"

export const ReportCardBGStyle = `rounded-md w-full bg-neutral-50 shadow-sm ${Theme.Colors.Shadow}`

export const ReportCardTileStyle = `text-white font-light text-sm w-fit px-2.5 pt-1 h-fit m-2 rounded-md shadow-sm`

export const TextInCardStyle = "text-neutral-900 font-light"

export const TextInPillStyle = `m-2 px-1 py-1 text-center text-white font-light text-sm rounded-xl shadow-sm`

export const MultiPageReportContentStyle = "aspect-w-2 aspect-h-3 w-[90%] h-full m-10"

export const MultiPageReportTitle = "my-2 text-ui-lime font-bold text-2xl"

export const MultiPageReportTableTitle = "mb-1 text-lg text-neutral-900"

export const MultiPageTableTextStyle = "border border-slate-50 text-sm"