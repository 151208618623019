import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface FullScreenPromptState {
    isActive: boolean;
    promptType: string
}

const initialState : FullScreenPromptState = {
    isActive : false,
    promptType: "loading"
}

export const fullScreenPromptSlice = createSlice({
    name: 'fullScreenPrompt',
    initialState,
    reducers: {
        activateFullScreenPrompt: (state, action: PayloadAction<string>) => {
            if(action.payload === "false") {
                state.isActive = false
            } else {
                state.isActive = true
                state.promptType = action.payload
            }
        },
    },
})

export const {activateFullScreenPrompt} = fullScreenPromptSlice.actions

export default fullScreenPromptSlice.reducer