import { Route, Routes, useSearchParams } from "react-router-dom";
import EditingPage from "./EditingPage";
import Home from "./Home";
import ReportView from "./ReportView";
import CustomerPage from "./CustomersPage"
import SherlockPage from "./SherlockPage";
import SherlockAdmin from "./SherlockAdmin";
import PageNotFound from "./PageNotFound";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { useEffect } from "react";
import { PageType, setCurrentControlBoard, setCurrentCustomerId, setCurrentReportId, setCurrentReportType } from "../redux/dataSlice";
import { IMReports, ReportType, ReportTypes } from "../models/ReportModel";
import Diagnostics from "./Diagnostics";
import RenderPage from "./RenderPage";

const PageLayout = () => {
    const invalidReports = useAppSelector(state => state.data.invalidReports)
    const validReports = useAppSelector(state => state.data.validReports)
    let reports:IMReports = {}
    let pageType:PageType = "" 

    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()
    let clientId = searchParams.get("clientId")
    let reportType = searchParams.get("reportType") as ReportType
    let reportId = searchParams.get("reportId")

    useEffect(() => {
        if (document.URL.includes("reportEditor")) {
            reports = invalidReports
            pageType = "editor"
        } else if (document.URL.includes("reportView")) {
            reports = validReports
            pageType = "view"
        }

        if (clientId && reportType && reportId) {
            if (!reports[clientId]) return 
            if (!ReportTypes.includes(reportType)) return
            if (!reports[clientId].hasOwnProperty(reportType)) return
            if (!reports[clientId][reportType]!.hasOwnProperty(reportId)) return 
            try {
                dispatch(setCurrentCustomerId(clientId))
                dispatch(setCurrentReportType(reportType))
                dispatch(setCurrentReportId({id: reportId, type: pageType}))  
                dispatch(setCurrentControlBoard(reportType))                   
            } catch (error) {
                dispatch(setCurrentReportId({id: "", type: pageType}))
            }
        }
    },[invalidReports,validReports,dispatch,clientId,reportType,reportId])  

    return (
          
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="reportEditor" element={<EditingPage />} />
                <Route path="reportEditor/*" element={<EditingPage />} />
                <Route path="reportView" element={<ReportView />} />
                <Route path="reportView/*" element={<ReportView />} />
                <Route path="customers" element={<CustomerPage />} />
                <Route path="customers/:customerId/*" element={<CustomerPage />} >
                    <Route path="baselines/:baselineId" element={<CustomerPage />} />
                    <Route path="healthchecks/:healthCheckId/:healthCheckFrequency/:healthCheckType" element={<CustomerPage />} />
                </Route>
                <Route path="sherlock" element={<SherlockPage />} />
                <Route path="sherlockAdmin" element={<SherlockAdmin />} />
                <Route path="diagnostics/*" element={<Diagnostics />} />
                <Route path="renderPage/*" element={<RenderPage />}>
                    <Route path="publishedReport/:customerId/:reportId" element={<RenderPage />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>

    )
}

export default PageLayout;