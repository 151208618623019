import { useAppSelector, useAppDispatch} from "../../redux/hook";
import { getFromPath } from "../../service/dataLogic";
import { addListData, checkForEditInSection} from "../../redux/dataSlice";
import { EditorStyle, INCIDENT_RESOLUTION_TARGETS_TEMPLATE, SERVICE_REQUEST_TARGETS_TEMPLATE } from "../../models/EditorModel";
import RoundButton from "../utility/RoundButton";
import ListRow from "./ListRow";
import { ListEditorProp } from "./ListEditor";
import Theme from "../../Theme";
import { EditorPageStyle } from "../../models/StyleModel";

/**
 * Create an editor to edit list in current report specified with 'path'. 
 * SPECIFY number of element if list item is a dictionary (for multiple columns) in colNum.
 */

const ListEditorWithTemplate = ({editorTitle, path} : ListEditorProp) => {
    let currentReport = useAppSelector((state) => state.data.currentReport)
    const dispatch = useAppDispatch()

    let rowTemplate: string|{[key:string]:string} = ""

    let style = EditorStyle.INCIDENT_RESOLUTION_TARGETS
    if (path.includes("incident_resolution_targets")) {
        rowTemplate = {"priority": "Priority","definition": "Definition","resolution_target": "Resolution Target","progress_reports": "Progress Reports"}
    } else if (path.includes("service_request_targets")) {
        style = EditorStyle.SERVICE_REQUEST_TARGETS
        rowTemplate = {"type": "Service Request Type","response_time": "Response Time"}
    }

    function generateRows(){
        let rows = []
        if (!currentReport) return  
        let section = getFromPath(currentReport,path) 
        if (!Array.isArray(section)) return 
        
        for(let i = 0; i < section.length; i++){
            rows.push(<ListRow placeholder={rowTemplate} key={i} path={path.concat('.',i.toString())} style={style}/>)
        }
        return rows
    }
    
    return(
        <div className={`${EditorPageStyle}`}>
            <h1 className="font-medium text-white text-xl mx-2 my-3">{editorTitle}</h1>

            {generateRows()}
            <div className=" flex w-full justify-center">
                <RoundButton text={"Add Row"} size={"text-lg"} colour={`${Theme.Colors.Primary}`} extraStyle={" font-medium flex-none w-48 h-fit py-1.5 my-3"}
                    onClickAction={()=>{
                        if (!currentReport) return
                        let section = getFromPath(currentReport,path) 
                        if (!Array.isArray(section)) return 
                        
                        if (section.length === 0) {
                            if (path.includes("incident_resolution_targets")) {
                                section = INCIDENT_RESOLUTION_TARGETS_TEMPLATE                                
                            } else if (path.includes("service_request_targets")) {
                                section = SERVICE_REQUEST_TARGETS_TEMPLATE
                            }

                            for(let i = 0; i < section.length; i++){
                                rowTemplate = section[i] as {[key:string]:string}
                                dispatch(addListData({"newRow":rowTemplate, "path": path}))
                            }
                        } else {
                            let emptyRowTemplate : string | {[key:string]:string}
                            if (typeof rowTemplate === 'object') {
                                emptyRowTemplate = rowTemplate
                                for (let dictKey in emptyRowTemplate) {
                                    emptyRowTemplate[dictKey] = ""
                                }
                            } else {
                                emptyRowTemplate = ""
                            }
                            dispatch(addListData({'newRow':emptyRowTemplate, 'path':path}));                            
                        }

                        dispatch(checkForEditInSection(path));}}
                />
            </div>
        </div>
    )
}

export default ListEditorWithTemplate