import { useAppSelector, useAppDispatch} from "../../redux/hook";
import ListRow from "./ListRow";
import { getFromPath } from "../../service/dataLogic";
import { addListData, checkForEditInSection} from "../../redux/dataSlice";
import RoundButton from "../utility/RoundButton";
import { EditorStyle } from "../../models/EditorModel";
import Theme from "../../Theme";
import { EditorPageStyle } from "../../models/StyleModel";

/**
 * Create an editor to edit list in current report specified with 'path'. 
 * SPECIFY number of element if list item is a dictionary (for multiple columns) in colNum.
 */
export interface ListEditorProp {
    editorTitle: string;
    path: string
}

function ListEditor({editorTitle, path} : ListEditorProp) {
    let currentReport = useAppSelector((state) => state.data.currentReport)
    const userRoles = useAppSelector(state => state.user.userRoles)
    const dispatch = useAppDispatch()
    let rowTemplate : string | {[key:string]:string} = "Enter Here"

    let style = EditorStyle.STR_INPUT_LIST
    if (path.includes("optimisation_activity")) {
        style = EditorStyle.TRIPLE_COL_INPUT
        rowTemplate = {"name":"Activity Name Here","status": "1:Done 2:Doing 3:On-hold 4:Not Started","date": "YYYY-MM-DD"}
    } else if (path.includes("document_history")) {
        style = EditorStyle.DOCUMENT_HISTORY
        rowTemplate = {"customer":"Customer Name","month_ending":"Month Ending: MM/YY","author":"Author Name","notes":"Notes"}
    } else if (path.includes("software_licenses")) {
        style = EditorStyle.LICENSING_STATUS_INPUT
        rowTemplate = {"asset":"Asset Name","license":"License Name","expiry":"Expiry Date: Month DD, YYYY","status": "Licensing Status"}
    } else if (path.includes("firewall_resource_availability")) {
        style = EditorStyle.FIREWALL_RESOURCE_AVAILABILITY
        rowTemplate = {"resource_group":"Resource Group Name","resource":"Resource Name","datasource":"Datasource Name","instance":"Instance Name","datapoint":"Datapoint","available":"Availability in Percentage","exclusions": "Exclusions"}
    } else if (path.includes("interface_utilization")) {
        style = EditorStyle.INTERFACE_UTILIZATION
        rowTemplate = {"resource":"Resource Name","interface":"Interface Name and Id","description":"Description","average_inbound":"Average Inbound","max_inbound":"Max Inbound","_95th_percentile_inbound":"95 Percentile Inbound","average_outbound":"Average Outbound","max_outbound":"Max Outbound","_95th_percentile_outbound":"95 Percentile Outbound"}
    } else if (path.includes("software_status")) {
        style = EditorStyle.SOFTWARE_STATUS
        rowTemplate = {"asset":"Asset Name","software_version":"Software Version","end_of_life":"End of Life","recommended_version":"Recommended Version","ticket_number":"Ticket Number"}
    } else if (path.includes("system_resources")) {
        style = EditorStyle.SYSTEM_RESOURCES
        rowTemplate = {"resource":"Resource Name","instance":"Instance Name","datapoint":"Datapoint","min":"Min in Percentage","max":"Max in Percentage","average":"Average in Percentage"}
    } else if (path.includes("open_tickets")) {
        style = EditorStyle.OPEN_TICKETS
        rowTemplate = {"ticket_key":"Ticket Key","summary":"Summary","description":"Description","issue_type":"Issue Type","time_created":"Time Created","sentinel_number":"Sentinel Number","status":"Status","oncall_hours":"Oncall Hours","sla_breached":"Sla Breached? false/true"}
    } else if (path.includes("alerts.by_source")) {
        style = EditorStyle.NAME_AND_COUNT
        rowTemplate = {"source": "Source Name", "count": "Count Number"}
    }

    function generateRows(){
        let rows = []
        if (!currentReport) return  
        let section = getFromPath(currentReport,path) 
        if (!Array.isArray(section)) return 
        
        for(let i = 0; i < section.length; i++){
            rows.push(<ListRow placeholder={rowTemplate} key={i} path={path.concat('.',i.toString())} style={style}/>)
        }
        return rows
    }

    return(
        <div className={`${EditorPageStyle}`}>
            <h1 className="font-medium text-white text-xl mx-2 my-3">{editorTitle}</h1>

            {generateRows()}
            <div className=" flex w-full justify-center">
                <RoundButton text={"Add Row"} size={"text-lg"} colour={`${Theme.Colors.Primary}`} extraStyle={" font-medium flex-none w-48 h-fit py-1.5 my-3"}
                    disabled={userRoles.includes("read_only") || (currentReport !== null && currentReport["finalised"] !== undefined && currentReport["finalised"] === true)}
                    onClickAction={()=>{
                        let emptyRowTemplate : string | {[key:string]:string}
                        if (typeof rowTemplate === 'object') {
                            emptyRowTemplate = rowTemplate
                            for (let dictKey in emptyRowTemplate) {
                                emptyRowTemplate[dictKey] = ""
                            }
                        } else {
                            emptyRowTemplate = ""
                        }
                        dispatch(addListData({'newRow':emptyRowTemplate, 'path':path}));
                        dispatch(checkForEditInSection(path));}}
                />
            </div>
        </div>
    )
}

export default ListEditor