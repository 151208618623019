import { useMsal } from "@azure/msal-react";
import RoundButton from "../components/utility/RoundButton";
import DefendLogo from "../resource/DEFENDLogo-White.png"
import Theme from "../Theme";
import HomeBackground from '../resource/DEFEND-Main-Home.jpg'

function LoginPage() {

    const {instance} = useMsal()
  
    function handleLogin() {
        instance.loginPopup()
            .catch((error) => console.log(error))
    }

    function handleLogout() {
        instance.logoutPopup()
            .catch((error) => console.log(error))
    }


    return (
        <div className={`flex w-screen h-screen items-center justify-center bg-cover`} style={{ backgroundImage: `url(${HomeBackground})`}}>
            <div className={`flex flex-col ${Theme.Colors.DarkWebBg} bg-opacity-70 rounded-lg w-[90%] justify-center items-center max-w-6xl h-fit`}>
                <img src={DefendLogo} className="w-40 h-40" alt="Defend Logo"></img>
                <h1 className="text-2xl font-medium text-white mb-6">SATURN Portal</h1>
                <div className="flex justify-center">
                    <RoundButton text={"Sign In"} size={"text-xl"} extraStyle={"m-2 w-32"} colour={Theme.Colors.NeonGreenBg} onClickAction={handleLogin}/>
                    <RoundButton text={"Sign Out"} size={"text-xl"} extraStyle={"m-2 w-32 mb-6"} colour={Theme.Colors.DarkWebBg} onClickAction={handleLogout}/>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;