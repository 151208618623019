import { useNavigate } from "react-router-dom";
import RoundButton from "../components/utility/RoundButton";
import MenuBar from "../components/MenuBar";
import Theme from "../Theme";
import HomeBackground from '../resource/DEFEND-Main-Home.jpg'

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className={`flex ${Theme.Colors.DarkWebBg}`}>
            <MenuBar onPage="home" />
            <div className={`${Theme.Colors.DarkWebBg} w-full flex flex-col items-center min-h-screen pl-44 bg-cover`} style={{ backgroundImage: `url(${HomeBackground})`}} >
                <div className={`w-[90%] h-1/2 mt-10 mb-10 shadow-2xl`}>
                    <h1 className={`text-white text-2xl font-medium pt-[30vh]`}>The request page cannot be found</h1>
                    <h5 className={`text-white text-lg py-6`}>The following URL is not valid: <strong>{document.URL}</strong></h5>
                    <RoundButton text="Go to home page" colour={`${Theme.Colors.Primary}`} extraStyle={`w-46 mb-2 `} onClickAction={() => { navigate("/") }}/>
                </div>
            </div> 
        </div>           
    )
}

export default PageNotFound