import { useEffect, useState } from 'react'
import { useAppSelector } from "../../redux/hook"
import { getSentinelBackupFiles, getSentinelBackupList } from "../../service/apiAccessLogic";
import { msalInstance } from "../../";
import RoundButton from '../utility/RoundButton';
import Theme from '../../Theme';

const DownloadBackupSelector = () => {
    const userRoles = useAppSelector(state => state.user.userRoles)

    const currentCustomerId = useAppSelector((state) => state.data.currentCustomerId)
    const [backupNames, setBackupNames] = useState([]);
    const [selectedBackup, setSelectedBackup] = useState('');

    useEffect(() => {
        setBackupNames([]);
        if (currentCustomerId) {
            getSentinelBackupList(msalInstance, currentCustomerId)
            .then(response => {
                response.data.text().then(function(text: string) {
                    try {
                        const jsonData = JSON.parse(text);
                        setBackupNames(jsonData);
                    } catch (error) {
                        console.error('Error parsing JSON content:', error);
                    }
                }).catch(function(error: any) {
                    console.error('Error reading the blob as text:', error);
                });
            })
            .catch(error => {
                console.error('Error fetching backup names:', error);
            });
        }
    }, [currentCustomerId])

   const download = (backup_name: String) => {
    getSentinelBackupFiles(msalInstance, backup_name, currentCustomerId).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sentinel-backups.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
    }

    return (
        <div className="pt-6 w-full h-fit flex flex-col items-center">
                <select 
                    value={selectedBackup}
                    onChange={(e) => setSelectedBackup(e.target.value)} 
                    className="w-40 py-1 focus:outline-none my-1 rounded-md shadow-inner bg-slate-200 text-slate-700 text-sm pl-1 disabled:brightness-50"
                >
                <option value="">Select backup</option>
                {backupNames.map((backup:any, index) => (
                    <option key={index} disabled={userRoles.includes("read_only")} value={backup.backup_container_name}>
                        {backup.backup_container_name}
                    </option>
                ))}
                </select>
                <RoundButton text={"Download"} disabled={userRoles.includes("read_only")} extraStyle={" w-40 mt-3"} colour={`${Theme.Colors.Primary}`} onClickAction={async () => download(selectedBackup)}/>
            </div>
    )
}

export default DownloadBackupSelector