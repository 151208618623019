import {
  AddServiceRequestIceImhr,
  AddServiceRequestSecureConnect,
  AddServiceRequestSherlock,
} from "../../../models/FormRequestModel";
import { FormDataState } from "../../../redux/formDataSlice";

const ComposeServiceConfigList = (data: FormDataState) => {
  const selectedservices = data.selected_products;
  let servicesConfigList: (
    | AddServiceRequestIceImhr
    | AddServiceRequestSecureConnect
    | AddServiceRequestSherlock
  )[] = [];
  selectedservices.forEach((product) => {
    const productId = product.toLowerCase().replace(/ /g, "_");
    switch (productId) {
      case "ice_imhr":
        const iceImhrConfig: AddServiceRequestIceImhr = {
          "id": productId,
          "config": {
            "jira_record": data.jira_record.ice_imhr,
            "squad_name": data.squad_name, 
            "sentinel_records": data.sentinel_records.map((r) => mapSentinelRecord(r)),
            "sentinel_metadata": data.sentinel_metadata,
            "teams_webhook": data.teams_webhook_ice,
          },
        };
        servicesConfigList = [...servicesConfigList, iceImhrConfig];
        break;

      case "secure_connect":
        const secureConnectConfig: AddServiceRequestSecureConnect = {
          "id": productId,
          "config": {
            "jira_record": data.jira_record.secure_connect,
            "logic_monitor": {
              "company_name": data.logic_monitor.company_name,
            },
            "teams_webhook": data.teams_webhook_secure_connect,
          },
        };
        servicesConfigList = [...servicesConfigList, secureConnectConfig];
        break;

      case "sherlock":
        const sherlockConfig: AddServiceRequestSherlock = {
          "id": productId,
          "config": {
            "sherlock_sub_id": data.sherlock_sub_id,
            "teams_webhook": data.teams_webhook_sherlock,
          },
        };
        servicesConfigList = [...servicesConfigList, sherlockConfig];
        break;

      default:
        break;
    }
  });
  return servicesConfigList;
};

const mapSentinelRecord = (sentinelRecord: any) => {
  return {
    "workspace": sentinelRecord.workspace,
    "workspace_id": sentinelRecord.workspace_id,
    "resource_group": sentinelRecord.resource_group,
    "subscription_id": sentinelRecord.subscription_id,
  };
}

export default ComposeServiceConfigList;
