import { FcCheckmark } from "react-icons/fc";
import { useAppSelector } from "../../../redux/hook";

const FormComplete = () => {
  const currentFormType = useAppSelector((state) => state.form.formType);
  let message = "";
  switch (currentFormType) {
    case "CustomerCreation":
      message = "Customer Successfully Created";
      break;
    case "CustomerManagement":
      message = "Customer Successfully Updated";
      break;
  }
  return (
    <div className="p-20">
      <span className="text-xl font-medium flex justify-center items-center gap-5">
        {message} <FcCheckmark />
      </span>
    </div>
  );
};

export { FormComplete };
