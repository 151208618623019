import { Fragment, useEffect, useMemo, useState } from "react"
import * as signalR from "@microsoft/signalr"
import { Sighting } from "../../models/SherlockModel"
import { msalInstance } from "../../index"
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { setAdditionalIOCs, setStreamSightings } from "../../redux/sherlockSlice"
import { ColumnDef, ColumnResizeMode, ExpandedState, PaginationState, Row, flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table"
import { getSherlockIndicatorById } from "../../service/apiAccessLogic"
import { parseFeedsSource } from "../../service/dataLogic"

const defaultEditorColumns: ColumnDef<Sighting>[] = [
    {
        header: "Sightings Stream",
        footer: props => props.column.id,
        columns: [
            {
                id: 'expander',
                header: () => <span>Indicator Details</span>,
                cell: ({ row }) => {
                  return row.getCanExpand() ? (
                    <button
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                      }}
                    >
                      {row.getIsExpanded() ? '- Fold IOC' : '+ Expand IOC'}
                    </button>
                  ) : (
                    'No Details Available'
                  )
                },
            }, 
            {
                accessorFn: row => atob(row.indicator),
                id: 'indicator',
                header: () => <span>Indicator</span>,
                footer: props => props.column.id
            },
            {
                accessorFn: row => row.date.replace("T", " ").slice(0, row.date.indexOf(".")),
                id: 'date',
                header: () => <span>Date</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'organisation_name',
                header: () => <span>Organisation Name</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'client_name',
                header: () => <span>Client Name</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'platform',
                header: () => <span>Platform</span>,
                footer: props => props.column.id
            }                                                                  
        ]
    }
]

const defaultClientColumns: ColumnDef<Sighting>[] = [
    {
        header: "Sightings Stream",
        footer: props => props.column.id,
        columns: [
            {
                id: 'expander',
                header: () => <span>Indicator Details</span>,
                cell: ({ row }) => {
                  return row.getCanExpand() ? (
                    <button
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                      }}
                    >
                      {row.getIsExpanded() ? '- Fold IOC' : '+ Expand IOC'}
                    </button>
                  ) : (
                    'No Details Available'
                  )
                },
            }, 
            {
                accessorFn: row => atob(row.indicator),
                id: 'indicator',
                header: () => <span>Indicator</span>,
                footer: props => props.column.id
            },
            {
                accessorFn: row => row.date.replace("T", " ").slice(0, row.date.indexOf(".")),
                id: 'date',
                header: () => <span>Date</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'organisation_name',
                header: () => <span>Organisation Name</span>,
                footer: props => props.column.id
            },
            {
                accessorKey: 'platform',
                header: () => <span>Platform</span>,
                footer: props => props.column.id
            }                                                                  
        ]
    }
]

const SightingWebsocket = () => {
    const userMode = useAppSelector(state => state.user.userMode)
    const signalRBaseUrl = process.env.REACT_APP_BACKEND_URL + "/api/?hub=sightings"
    const backendClientScope = "api://" + process.env.REACT_APP_BACKEND_APP_ID + "/saturn_user_impersonation"
    const sightings = useAppSelector(state => state.sherlock.streamSightings)
    const pageSizeSetting = useAppSelector(state => state.sherlock.pageSize)
    const connectionMethod = useAppSelector(state => state.user.sherlockWebsocketMethod)
    const streamSightingsDisplay = useAppSelector(state => state.sherlock.streamSightingsDisplay)
    const pagedIOCs = useAppSelector(state => state.sherlock.pagedIOCs)
    const additionalIOCs = useAppSelector(state => state.sherlock.additionalIOCs)    
    const dispatch = useAppDispatch()

    const [columns, setColumns] = useState<ColumnDef<Sighting>[]>([])
    const [columnResizeMode] = useState<ColumnResizeMode>("onChange")
    const [expanded, setExpanded] = useState<ExpandedState>({})
    
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: pageSizeSetting
    })

    const paginatedSightings = sightings.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)

    const pagination = useMemo(() => ({
        pageIndex,
        pageSize
    }), [pageIndex, pageSize])

    const getRowCanExpand = (row: Row<Sighting>) => {
        return row.original.indicator_id !== "Unknown"
    }

    const searchIndicator = (indicator:string) => {
        let ioc = pagedIOCs.find(item => item.id === indicator)
        if (ioc) {  
            return ioc
        }
    
        ioc = additionalIOCs.find(item => item.id === indicator)
        if (ioc) {      
            return ioc     
        }
    
        getSherlockIndicatorById(msalInstance, indicator).then((response) => {
            dispatch(setAdditionalIOCs(response.data))
            return response.data[0]
        }).catch((error) => {
            if (error.response && error.response.status === 404) { 
                return undefined          
            }
        }) 
    }

    const renderSubComponent = ({row}: {row: Row<Sighting>}) => {
        let expandedIOC = searchIndicator(row.original.indicator)
        
        if (expandedIOC === undefined) {
            return (
                <div>Searching indicator...</div>
            )
        } else {
            return (
            <div>
            <div>Indicator details:</div>
            <table>
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td>{expandedIOC.id}</td>
                    </tr>
                    <tr>
                        <td>Info</td>
                        <td>{expandedIOC.info}</td>
                    </tr> 
                    <tr>
                        <td>Indicator ID</td>
                        <td>{expandedIOC.indicator_id}</td>
                    </tr>                     
                    <tr>
                        <td>Org ID</td>
                        <td>{expandedIOC.org_id}</td>
                    </tr>  
                    <tr>
                        <td>Timestamp</td>
                        <td>{expandedIOC.timestamp}</td>
                    </tr>  
                    <tr>
                        <td>Expiry Date</td>
                        <td>{expandedIOC.expiry_date}</td>
                    </tr> 
                    <tr>
                        <td>Feeds</td>
                        <td>{parseFeedsSource(expandedIOC.feeds)}</td>
                    </tr>                                                                                                
                </tbody>
            </table>
            </div>        
            )            
        }
    }

    const table = useReactTable({
        data: paginatedSightings || [],
        columns,
        pageCount: Math.ceil(sightings.length / pageSize),
        state: { pagination, expanded },
        onPaginationChange: setPagination,
        onExpandedChange: setExpanded,        
        manualPagination: true,         
        columnResizeMode,
        getRowCanExpand,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel()
    })

    useEffect(() => {
        var tokenRequest = {
                account: msalInstance.getAllAccounts()[0],
                scopes: [backendClientScope]
            };
        msalInstance.acquireTokenSilent(tokenRequest)
            .then(response => {
                const token = response.accessToken 

                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(signalRBaseUrl, {
                        accessTokenFactory: () => token
                    })
                    .build()
                
                connection.on(connectionMethod, (sighting:Sighting) => {
                    dispatch(setStreamSightings(sighting))
                })

                connection.start().catch(err => console.error("Failed to connect to SingnalR")) 
                return () => { connection.stop() }                 
            })
            .catch(err => {console.error("Failed to acquire token silently", err)})         
    },[])

    useEffect(() => {
        if (userMode === "editor") {
            setColumns([...defaultEditorColumns])
        } else {
            setColumns([...defaultClientColumns])
        }
    }, [userMode])    

    return (
        <div className={`${streamSightingsDisplay?"block":"hidden"} w-[90%] max-w-screen-2xl mt-5 shadow-2xl bg-white bg-opacity-90 overflow-x-auto`}>
            {/* Table body */}
            <table 
                className="border border-gray-300 w-auto"
                style={{
                width: table.getCenterTotalSize(),
                }}
            >
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="w-auto h-7">
                    {headerGroup.headers.map(header => (
                    <th
                        key={header.id}
                        colSpan= {header.colSpan}
                        className={`border border-gray-300 p-1 text-center font-bold h-7 relative}`}
                        style= {{
                            width: header.getSize(),
                            whiteSpace: "nowrap"
                        }}
                        >
                        {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                        )}
                        <div
                            className={`absolute right-0 top-0 h-full w-1.5 bg-gray-700 cursor-col-resize select-none ${header.column.getIsResizing() ? "bg-blue-500 opacity-100" : "opacity-0"}`}                      
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            style={{
                            transform:
                            columnResizeMode === 'onEnd' &&
                                header.column.getIsResizing() ?  
                                    `translateX(${
                                    table.getState().columnSizingInfo.deltaOffset
                                    }px)` : "",
                            }}
                        />
                    </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => {
                return (
                <Fragment key={row.id}>
                <tr key={row.id} className="w-auto h-7">
                    {row.getVisibleCells().map(cell => (
                    <td
                        key={cell.id}
                        className="border border-gray-300 h-7"
                        style={{
                            width: cell.column.getSize(),
                            padding: "0 6px",
                            whiteSpace: "nowrap"                            
                        }}
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                    ))}
                </tr>
                {row.getIsExpanded() && (
                  <tr>
                    {/* 2nd row is a custom 1 cell row */}
                    <td colSpan={row.getVisibleCells().length}>
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}                   
                </Fragment>
            )})}
            </tbody>
            </table>

            {/* Pagination controller */}
            <div className="flex items-center gap-2">
            <button
            className="border rounded p-1"
            onClick={() => {
                table.setPageIndex(0)
                setExpanded({})
            }}
            disabled={!table.getCanPreviousPage()}
            >
            {'<<'}
            </button>
            <button
            className="border rounded p-1"
            onClick={() => {
                table.previousPage()
                setExpanded({})
            }}
            disabled={!table.getCanPreviousPage()}
            >
            {'<'}
            </button>
            <button
            className="border rounded p-1"
            onClick={() => {
                table.nextPage()
                setExpanded({})
            }}
            disabled={!table.getCanNextPage()}
            >
            {'>'}
            </button>
            <button
            className="border rounded p-1"
            onClick={() => {
                table.setPageIndex(table.getPageCount() - 1)
                setExpanded({})
            }}
            disabled={!table.getCanNextPage()}
            >
            {'>>'}
            </button>
            <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
            </strong>
            </span>
            <span className="flex items-center gap-1">
            | Go to page:
            <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
                setExpanded({})
                }}
                className="border p-1 rounded w-16"
            />
            </span>
            </div>            
        </div>
    )
}

export default SightingWebsocket