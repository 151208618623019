import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../redux/hook"
import { getDiagnosticsData } from "../service/apiAccessLogic"
import { msalInstance } from "../index"
import { addAuditLogs, setHealthStatus, setReleaseNoteContent } from "../redux/metaDataSlice"
import MenuBar from "../components/MenuBar"
import TopNavBar from "../components/TopNavBar"
import CustomerBackground from "../resource/DEFEND-Main-About.jpg";
import Theme from "../Theme"
import { EditorPageStyle } from "../models/StyleModel"
import Breadcrumb from "../components/utility/Breadcrumb"
import { Link, Route, Routes } from "react-router-dom"
import ReleaseNoteView from "../components/diagnostics/ReleaseNotesView"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import PageNotAccessable from "./PageNotAccessable"
import SystemDiagnosticsView from "../components/diagnostics/SystemDiagnosticsView"
import AuditLogsView from "../components/diagnostics/AuditLogsView"


const Diagnostics = () => {
    const userMode = useAppSelector(state => state.user.userMode)
    const pathSegments = window.location.pathname.split("/").filter(Boolean);
    const releaseNoteContent = useAppSelector(state => state.metaData.releaseNoteContent)
    const diagnostics = useAppSelector(state => state.metaData.diagnostics)
    const auditLogs = useAppSelector(state => state.metaData.auditLogs)
    const apiDataLimit = 25
    const [apiDataAmount, setApiDataAmount] = useState<number>(apiDataLimit)
    const dispatch = useAppDispatch()

    useEffect(() => {
      if (pathSegments.includes("releasenotes")) {
        if (releaseNoteContent.length !== 0) return 

        getDiagnosticsData(msalInstance, "release_notes").then((response) => {
          dispatch(setReleaseNoteContent(response.data))
        }).catch((e) => {
          console.error("Error getting release note: ", e)
        } 
        )    
      } else if (pathSegments.includes("auditlogs")) {
        if (apiDataAmount !== apiDataLimit) return 

        const fetchData = async () => {
          const response = await getDiagnosticsData(msalInstance, "audit_logs", auditLogs.length, apiDataLimit)
          const data = response.data
          dispatch(addAuditLogs(data))

          if (data.length !== apiDataLimit) {
            setApiDataAmount(data.length)
          }
        }

        fetchData().catch((e) => {console.error("Error getting audit logs: ", e)})     
      } else if (pathSegments.includes("systemdiagnostics")) {
        if (diagnostics !== null) return 

        getDiagnosticsData(msalInstance, "diagnostics").then((response) => {
          dispatch(setHealthStatus(response.data))
        }).catch((e) => {
          console.error("Error getting Saturn diagnostics: ", e)
        } 
        )
      }

    }, [pathSegments, apiDataAmount])

    const renderDiagnoticsComponent = () => {
      let style = `flex flex-row items-center gap-2`;
      let iconSize = 18;
      return (
        <div className={`${EditorPageStyle} w-full h-60 flex flex-col justify-center gap-4 text-white pl-10`}>
          <span className="items-center font-semibold text-lg text-green-700">SATURN Diagnostics</span>
          <span className={style}><MdKeyboardDoubleArrowRight size={iconSize}/>Find out what's new in SATURN! See <Link to="/diagnostics/releasenotes" className="underline hover:text-blue-600">Release Notes</Link></span>
          <span className={style}><MdKeyboardDoubleArrowRight size={iconSize}/>Wonder who is doing what in SATURN? See <Link to="/diagnostics/auditlogs" className="underline hover:text-blue-600">Audit Logs</Link></span>
          <span className={style}><MdKeyboardDoubleArrowRight size={iconSize}/>Checking SATURN system health - See <Link to="/diagnostics/systemdiagnostics" className="underline hover:text-blue-600">System Diagnostics</Link></span>
        </div>
      )
    }

    if (userMode === "editor") {
      return (
        <div className={`flex ${Theme.Colors.DarkWebBg}`}>
          <MenuBar onPage="home" />
          <div className={`w-full flex flex-col items-center min-h-screen pl-44 bg-cover`} style={{ backgroundImage: `url(${CustomerBackground})` }}>
            <TopNavBar />
            <span className={`place-self-start flex pl-16 pt-4`}>
              <Breadcrumb pathSegments={pathSegments} startFrom="diagnostics" />
            </span>
            <div className={`w-full flex flex-col items-center min-h-screen p-4`}>
              <Routes>
                <Route element={renderDiagnoticsComponent()} path={""} />
                <Route element={<ReleaseNoteView />} path={"releasenotes"} />
                <Route element={<AuditLogsView />} path={"auditlogs"} />
                <Route element={<SystemDiagnosticsView />} path={"systemdiagnostics"} />
              </Routes>
            </div>
          </div>
        </div>
      );      
    } else {
      return <PageNotAccessable />
    }
  }


export default Diagnostics