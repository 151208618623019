import { useAppSelector } from "../../redux/hook"
import { getFromPath, sectionNameToUpperCase } from "../../service/dataLogic"
import { ActivitySummary, AlertsByClassification, AlertsBySeverity, OpenTickets, SentinelHealthSection, TicketByHours, TicketTimes } from "../../models/ReportModel"
import HealthBadge from "../utility/HealthBadge"
import PieChartColorFromData, { PieChartData } from "../report/PieChartColorFromData"
import PieChart from "../report/PieChartColorFromScheme"
import DefendLogo from "../utility/DefendLogo"
import { ReportCardBGStyle, ReportCardTileStyle, ReportPageStyle, TextInCardStyle, TextInPillStyle } from "../../models/StyleModel"
import Theme from "../../Theme"
import { LineChartData, LineChartDataType } from "../report/LineChart"
import LineChartForTicketByHours from "../report/LineChartForTicketByHours"

const IMWeeklyReport = () => {
    const currentReport = useAppSelector((state) => state.data.currentReport)

    const renderHeadContent = () => {
        if (currentReport === null) return
        const startDate = getFromPath(currentReport, "content.start_date") as string
        const endDate = getFromPath(currentReport, "content.end_date") as string
        const customerName = getFromPath(currentReport, "content.company_name") as string
        return (
            <div className="my-3 px-2 py-2 border-[1px] rounded-md border-neutral-900">
                <div className="flex flex-row justify-between">
                    <div className="py-1 flex flex-col gap-2">
                        <h1 className="text-xs text-neutral-900">WEEKLY REPORT:</h1>
                        <h1 className="text-xs text-neutral-900">{customerName}</h1>
                    </div>
                    <div className="py-1 flex flex-col items-end gap-2">
                        <h1 className="text-xs text-neutral-900">FROM: {startDate}</h1>
                        <h1 className="text-xs text-neutral-900">TO: {endDate}</h1>
                    </div>
                </div>
                <div>
                    <h3 className="text-xs text-neutral-900">Incident Management, Handling and Response</h3>
                </div>
            </div>
        )
    }

    const renderSentinelHealthContent = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.microsoft_sentinel_health") as unknown as SentinelHealthSection
        
        return (
            <div className="px-4 pt-4 flex flex-row">
                <div className="w-3/4 flex flex-col gap-2">
                    {Object.entries(content).map(([key, value]) => (
                        <div className="grid grid-cols-12 border-b-[1px] border-neutral-900" key={key}>
                            <div className={`col-span-4 place-self-end text-neutral-900 text-sm`}>{key.split("_").join(" ").toUpperCase()}</div>
                            <div className="col-span-1 place-self-center"><HealthBadge status={value.status}/></div>
                            <div className={`col-span-7 ${TextInCardStyle} text-sm`}>{value.info}</div>
                        </div>
                    ))}
                </div>
                <div className="w-1/4 self-end">
                    <div className={`w-[80%] ${Theme.Colors.Primary} ${TextInPillStyle}`}>Healthy</div>
                    <div className={`w-[80%] bg-orange-500 ${TextInPillStyle}`}>Action Required</div>
                    <div className={`w-[80%] bg-red-500 ${TextInPillStyle}`}>Error/Disruption</div>
                </div>
            </div>
        )
    }

    const renderAlertsBySeverity = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.alerts.alerts_by_severity") as unknown as AlertsBySeverity
        const chartArr: PieChartData[] = []
        let color:string = ""
        for (let key in content) {
            if (key === "high") {color = "rgb(239 68 68)"}
            else if (key === "low") {color = "rgb(147 183 83)"}
            else if (key === "medium") {color = "rgb(249 115 22)"}
            else {color = "rgb(107 114 128)"} 
            chartArr.push({id:key,label:key,value:content[key as keyof AlertsBySeverity],color:color})
        }

        return (
            <div className="h-[80%]">
                <PieChartColorFromData data={chartArr} dataType="Alerts" />                   
            </div>
        )
    }

    const renderAlertsByClassification = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.alerts.alerts_by_classification") as unknown as AlertsByClassification
        const chartArr: PieChartData[] = []
        let color:string = ""
        for (let key in content) {
            let text = ""
            if (key === "benignpositive") {text = "Benign Positive"; color = "rgb(147 183 83)"}
            else if (key === "falsepositive") {text = "False Positive"; color = "rgb(249 115 22)"}
            else if (key === "truepositive") {text = "True Positive"; color = "rgb(239 68 68)"}
            else continue
            chartArr.push({id:key,label:text,value:content[key as keyof AlertsByClassification], color:color})
        }
        
        return (
            <div className="h-[80%]">
                <PieChartColorFromData data={chartArr} dataType="Alerts" />
            </div>
        )
    }

    const renderTicketsTimes = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.tickets.ticket_times") as unknown as TicketTimes
        const chartArr: PieChartData[] = []
        for (let key in content) {
            let text = ""
            if (key === "tickets_after") {text = "After Hours"}
            else if (key === "tickets_business") {text = "Business Hours"}
            chartArr.push({id:key,label:text,value:content[key as keyof TicketTimes]})
        }
        
        return (
            <div className="h-[80%]">
                <PieChart data={chartArr} dataType="Tickets" />
            </div>
        )
    }

    const renderTicketsByHours = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.tickets.ticket_by_hours") as unknown as TicketByHours
        const lineData: LineChartDataType[] = []
        for (let i = 0; i < 24; i++) {
            if (content[String(i)] > 0) {
                lineData.push({x: String(i), y: content[String(i)]})
            } else {
                lineData.push({x: String(i), y: 0})
            }
        }
        const chartData: LineChartData[] = [
            {id: "TicketByHours", data: lineData, color: "rgb(147 183 83)"}
        ]
        return (
            <div className="h-[80%]">
                <LineChartForTicketByHours data={chartData}/>
            </div>
        )
    }

    const renderActivitySummary = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.activity_summary") as unknown as ActivitySummary

        return (
            <div className="flex flex-col px-4 pb-4 gap-2">
                {Object.entries(content).map(([key,value]) => {
                    const text = sectionNameToUpperCase(key)

                    return(
                        <div className="w-[80%] flex flex-row justify-between border-b-[1px] border-b-neutral-900" key={key}>
                            <div className={`font-light text-sm text-neutral-900 `}>{text}</div>
                            <div className={`font-light text-sm text-neutral-900 `}>{value}</div>
                        </div>
                    )}
                )}
            </div>
        )
    }

    const renderOpenTickets = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.tickets.open_tickets") as unknown as OpenTickets

        return (
            <div className="mx-8 mt-4 flex flex-col gap-1">
                {content.map((ticket,index) => (
                    <div className="flex flex-row" key={index}>
                        <div className={`${Theme.Colors.Primary} w-1 h-5 place-self-center`} />
                        <h1 className={`px-2 text-sm ${TextInCardStyle}`}>{ticket.sentinel_number}: {ticket.summary}</h1>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div id="ReportContainer" className={`${ReportPageStyle} w-full grid grid-cols-3 gap-2`}>
            {/*Left side*/}
            <div className="col-span-1 grid grid-rows-10 gap-2 relative">
                <div className="row-span-2">
                    <DefendLogo />
                    <h1 className="text-neutral-900 overline decoration-neutral-900">SECURITY OPERATIONS DASHBOARD</h1>
                    {renderHeadContent()}
                </div>
                {/*Alerts By Severity*/}
                <div className={`row-span-4 ${ReportCardBGStyle}`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>ALERTS BY SEVERITY</h1>
                    {renderAlertsBySeverity()}
                </div>   
                {/*Activity Summary*/}
                <div className={`row-span-2 ${ReportCardBGStyle}`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>ACTIVITY SUMMARY</h1>
                    {renderActivitySummary()}
                </div>                              
            </div>

            {/*Right side*/}
            <div className="col-span-2 grid grid-rows-10 grid-cols-2 gap-2 relative">
                {/*Sentinel Health*/}
                <div className={`col-span-2 row-span-3 ${ReportCardBGStyle}`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>SENTINEL HEALTH</h1>
                    {renderSentinelHealthContent()}
                </div>

                {/*Alerts By Classification*/}
                <div className={`row-span-4 ${ReportCardBGStyle}`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>ALERTS BY CLASSIFICATION</h1>
                    {renderAlertsByClassification()}
                </div>  
                {/*Tickets By Hour*/}
                <div className={`row-span-4 ${ReportCardBGStyle}`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>TICKETS BY HOUR</h1>
                    {currentReport
                        && (getFromPath(currentReport, "content.tickets") as unknown as Object).hasOwnProperty("ticket_by_hours")  ?
                        renderTicketsByHours()
                        :
                        renderTicketsTimes()
                    }
                </div>   

                {/*Open Tickets*/}
                <div className={`col-span-2 row-span-3 ${ReportCardBGStyle}`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>OPEN TICKETS DETAILS</h1>
                    {renderOpenTickets()}
                </div>  
            </div>
                                               
        </div>
    )
}

export default IMWeeklyReport