import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { updateData} from "../../redux/dataSlice";
import { getFromPath } from "../../service/dataLogic";
import { checkForEditInSection } from "../../redux/dataSlice";
import { useEffect, useState } from "react";
import { EditorStyle } from "../../models/EditorModel";

/**
 * 
 * @param {*} path String pointing to target field. (i.e. "content.alerts.high")
 * @param {*} style editor style to define regex and rows.
 */

interface EditingBoxProp {
    placeholder: string;
    path: string;
    style: EditorStyle
}

function EditingBox({placeholder, path, style} : EditingBoxProp) {
    const dispatch = useAppDispatch()
    const currentReport = useAppSelector((state) => state.data.currentReport)
    const userRoles = useAppSelector(state => state.user.userRoles)
    const [sectionValue, setSectionValue] = useState<string|number>("")

    let pathToSection = path
    let regex = "^[0-9]*$"
    let isParagraph = false
    let rows = 2
    
    if (style === EditorStyle.NUM_INPUT) {
        pathToSection = path.substring(0, path.lastIndexOf("."))
    } else if (style === EditorStyle.DOUBLE_COL_INPUT) {
        isParagraph = true 
        regex = "^.*$"            
        
        pathToSection = path.substring(0, path.lastIndexOf("."))
        pathToSection = pathToSection.substring(0, pathToSection.lastIndexOf("."))
    } else if (style === EditorStyle.WORKSPACE_COST_ANALYSIS) {

        if (path.includes("workspace_name") || path.includes("cost_anomaly_detected")) {
            regex = "^.*$"
        } else {
            regex = "^\\d+(\\.?\\d*)?$"
        }

        pathToSection = path.substring(0, path.lastIndexOf("."))
        pathToSection = pathToSection.substring(0, pathToSection.lastIndexOf("."))
    } else if (style === EditorStyle.STR_INPUT) {
        isParagraph = true
        regex = "^.*$"
    } else if (style === EditorStyle.MULTI_LINE_STR) {
        regex = "[^]*$"
        isParagraph = true
        rows = 10
    } else if (style === EditorStyle.NAME_AND_COUNT) {
        if (path.includes(".source")) {
            regex = "^.*$" 
        } else if (path.includes(".count")) {
            regex = "^[0-9]*$"
        }

        pathToSection = path.substring(0, path.lastIndexOf("."))
        pathToSection = pathToSection.substring(0, pathToSection.lastIndexOf("."))
    } else if (style === EditorStyle.STR_INPUT_LIST) {
        isParagraph = true 
        rows = 4
        regex = "[^]*$"
        pathToSection = path.substring(0, path.lastIndexOf("."))
    } else if (style === EditorStyle.TRIPLE_COL_INPUT) {
        if (path.includes("name")) {
            regex = "^.*$"
        } else if (path.includes("date")) {
            regex = "^([0-9]|$){4}(-|$)([0-9]|$){2}(-|$)([0-9]|$){2}$"
        }

        pathToSection = path.substring(0, path.lastIndexOf("."))
        pathToSection = pathToSection.substring(0, pathToSection.lastIndexOf("."))
    } else if (style > 8) {
        isParagraph = true
        regex = "[^]*$"
        pathToSection = path.substring(0, path.lastIndexOf("."))
        pathToSection = pathToSection.substring(0, pathToSection.lastIndexOf("."))                
    }

    const updateSection = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //try converting input to number
        let value : string | number = event.target.value
        let reg = new RegExp(regex)

        if (regex === "^[0-9]*$" || regex === "^\\d+(\\.?\\d*)?$") {
            if (value === "") {
                value = 0
                // empty string will not pass regex test, so update section value and report data here
                setSectionValue(value)
                dispatch(updateData({'newValue':value,'path':path}))
                dispatch(checkForEditInSection(pathToSection))
                return
            } else if (!isNaN(Number(value)) && value !== "" && !value.endsWith(".")){
                if (Number.isInteger(Number(value))) {
                    value = parseInt(event.target.value)
                } else {
                    value = parseFloat(event.target.value)
                }
            } else if (!isNaN(Number(value)) && value !== "" && value.endsWith(".")) {
                if (regex === "^\\d+(\\.?\\d*)?$") {
                    // float number like 1.0 will be treated as 1, so if we parseFloat here, decimal point will be removed automatically
                    // will only update display and not the value in report when input ends with .
                    setSectionValue(event.target.value)
                    return                     
                } else {
                    value = parseInt(value.replace(".", ""))
                }

            }            
        } else {
            value = event.target.value
        }


        if (reg.test(event.target.value)){
            setSectionValue(value)             
            dispatch(updateData({'newValue':value,'path':path}));
        } 
        dispatch(checkForEditInSection(pathToSection))
       
    }

    useEffect(() => {
        if (!currentReport) return  
        const sectionValue = getFromPath(currentReport,path)

        if (typeof sectionValue === "string" || typeof sectionValue === "number") {
            setSectionValue(sectionValue)            
        } 
        if (typeof sectionValue === "boolean") {
            setSectionValue(sectionValue.toString())
        }
    },[currentReport,path])

    const disableInput = () => {
        if (userRoles.includes("read_only")) {
            return true
        }
        if (currentReport !== null && currentReport["finalised"] !== undefined && currentReport["finalised"] === true) {
            return true
        } else {
            return false
        }
    }

    return(
        <div className="p-1">
            {isParagraph ?
                <textarea 
                    disabled = { disableInput() }
                    placeholder = {placeholder}
                    className= {`bg-white w-full shadow-inner text-ui-darkweb px-3 py-2 rounded-md`} 
                    value={sectionValue}
                    rows={rows}
                    onChange={ updateSection }/>
                :
                <input 
                    disabled = { disableInput() }
                    placeholder = {placeholder}
                    className= {`bg-white w-full shadow-inner text-ui-darkweb px-3 py-2 rounded-md`} 
                    value ={sectionValue}
                    onChange={ updateSection }
                />
            }   
        </div>
    )
}



export default EditingBox