import Theme from "../../Theme"
import { ReportCardBGStyle, ReportCardTileStyle, ReportPageStyle } from "../../models/StyleModel"
import { useAppSelector } from "../../redux/hook"
import { getFromPath } from "../../service/dataLogic"
import TrendMark, { TrendMarkProp } from "../utility/TrendMark"
import DefendLogo from "../utility/DefendLogo"
import { LineChartData } from "../report/LineChart"
import { CountByClient, CountByOrg, CountByThreatLevel, SightedIOC, SightingsCountByDate } from "../../models/ReportModel"
import CountLineChart from "../sherlock/CountLineChart"
import { PieChartData } from "../report/PieChartColorFromData"
import PieChartColorFromScheme from "../report/PieChartColorFromScheme"


const SherlockReport = () => {
    const currentReport = useAppSelector(state => state.data.currentReport)

    const renderHeadContent = () => {
        if (currentReport === null) return
        const startDate = getFromPath(currentReport, "content.start_date") as string
        const endDate = getFromPath(currentReport, "content.end_date") as string
        const subTitle = (getFromPath(currentReport, "report_id") as string).includes("month") ? "SHERLOCK MONTHLY REPORT" : "SHERLOCK WEEKLY REPORT"
        return (
            <div>
                <DefendLogo />
                <h1 className="text-neutral-900 overline decoration-neutral-900">{subTitle}</h1>
                <div className="my-3 px-2 py-2 flex flex-col border-[1px] rounded-md border-neutral-900">
                    <div className="flex flex-row justify-between">
                        <h1>FROM:</h1>
                        <h1>{startDate}</h1>
                    </div>
                    <div className="flex flex-row justify-between">
                        <h1>TO:</h1> 
                        <h1>{endDate}</h1>
                    </div>
                </div>
            </div>
        )
    }

    const renderCount = (type:"indicators"|"sightings") => {
        if (currentReport === null) return 
        const current = getFromPath(currentReport, `content.${type}.current_count`) as number
        let previous = 0
        let trend:TrendMarkProp = "equal"
        
        const reportType = (getFromPath(currentReport, "report_id") as string).includes("month") ? "month" : "week"
        if (reportType === "month") {
            previous = getFromPath(currentReport, `content.${type}.previous_count`) as number
            trend = current > previous ? "up" : current === previous ? "equal" : "down"
        }

        return (
            <div className="m-2 flex flex-col">
                <div className={`text-neutral-900 py-8 text-center text-3xl`}>{current}</div>
                {reportType === "month" && 
                    <div className={`place-self-center`}>
                        <TrendMark trend={trend} difference={0} /> 
                    </div>                
                }
            </div>
        )
    }    

    const renderSightingsCountByDate = () => {
        if (currentReport === null) return
        const counts = getFromPath(currentReport, "content.sightings.count_by_date") as unknown as SightingsCountByDate[]
        const formattedCounts: LineChartData[] = [{"id": "Count", "data": []}]
        const startDate = new Date(getFromPath(currentReport, "content.start_date") as string + " UTC")
        const endDate = new Date(getFromPath(currentReport, "content.end_date") as string + " UTC")

        for (let date = startDate; date <= endDate; date.setUTCDate(date.getUTCDate() + 1)) {
            const formattedDate = date.toISOString().split("T")[0]
            let count = counts.find(item => item.date === formattedDate)
            let amount = count ? count.count : 0

            formattedCounts[0].data.push({
                x: formattedDate.slice(5),
                y: amount
            })
        }

        return (
            <div className="h-[90%]">
                <CountLineChart data={formattedCounts} />
            </div>
        )
    }

    const renderSightedIOCsRank = () => {
        if (currentReport === null) return
        const counts = getFromPath(currentReport, "content.sighted_iocs_rank") as unknown as SightedIOC[]
        let tableArr = []
        if (counts.length > 5) {
            tableArr.push([...counts].slice(0,5))
            tableArr.push([...counts].slice(5))
        } else {
            tableArr.push([...counts])
        }
        
        return (
            <div className="w-full flex flex-row justify-between">
                {tableArr.map((section, index) => {
                    return (
                        <table className="w-full border-separate border-spacing-1" key={index}>
                            <thead className="bg-gray-500">
                                <tr>
                                    <th className="rounded-sm text-sm text-white font-medium">INDICATOR</th>
                                    <th className="rounded-sm text-sm text-white font-medium">COUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {section.map((item, i) => {
                                    return (
                                        <tr className="bg-gray-100" key={i}>
                                            <td className="rounded-sm text-sm">{atob(item.indicator)}</td>
                                            <td className="rounded-sm text-sm">{item.count}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                })}
            </div>
        )
    }

    const renderOrgPieChart = (path: string) => {
        if (currentReport === null) return
        const counts:CountByOrg[] = getFromPath(currentReport, path) as unknown as CountByOrg[]
        const pieChartData: PieChartData[] = []

        const dataType:string = path.includes("indicators") ? "Indicators" : "Sightings"
        counts.forEach((count) => {
            pieChartData.push({
                "id": count.organisation_name,
                "label": count.organisation_name,
                "value": count.count
            })
        })

        return (
            <div className="h-[80%]">
                <PieChartColorFromScheme data={pieChartData} dataType={dataType} />
            </div>
        )
    }

    const renderThreatLevelPieChart = () => {
        if (currentReport === null) return
        const counts:CountByThreatLevel[] = getFromPath(currentReport, "content.indicators.count_by_threat_level") as unknown as CountByThreatLevel[]
        const pieChartData: PieChartData[] = []

        counts.forEach((count) => {
            pieChartData.push({
                "id": count.threat_level_id,
                "label": count.threat_level_id,
                "value": count.count
            })
        })

        return (
            <div className="h-[80%]">
                <PieChartColorFromScheme data={pieChartData} dataType="Indicators" />
            </div>
        )
    }

    const renderClientPieChart = () => {
        if (currentReport === null) return
        const counts:CountByClient[] = getFromPath(currentReport, "content.sightings.count_by_client") as unknown as CountByClient[]
        const pieChartData: PieChartData[] = []

        counts.forEach((count) => {
            pieChartData.push({
                "id": count.client_name,
                "label": count.client_name,
                "value": count.count
            })
        })

        return (
            <div className="h-[80%]">
                <PieChartColorFromScheme data={pieChartData} dataType="Sightings" />
            </div>
        )
    }        

    return (
        <div id="ReportContainer" className={`${ReportPageStyle} w-full grid grid-cols-12 gap-2`}>
            {/*Head*/}
            <div className="col-span-3 relative">
                {renderHeadContent()}
            </div> 
            {/*Incidents*/}
            <div className={`col-span-3 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>INDICATORS</h1>
                {renderCount("indicators")}
            </div> 
            {/*IOCs count by feed*/}
            <div className={`col-span-3 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>IOCs COUNT BY FEED</h1>
                {renderOrgPieChart("content.indicators.count_by_feed")}
            </div>  
            {/*IOCs count by threat level*/}
            <div className={`col-span-3 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>IOCs COUNT BY THREAT LEVEL</h1>
                {renderThreatLevelPieChart()}
            </div> 

            <div className="col-span-3 relative flex flex-col gap-2">
                {/*Sightings*/}
                <div className={`${ReportCardBGStyle} relative`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>SIGHTINGS</h1>
                    {renderCount("sightings")}
                </div> 
                {/*Sightings count by org*/}
                <div className={`grow ${ReportCardBGStyle} relative`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>SIGHTINGS COUNT BY ORG</h1>
                    {renderOrgPieChart("content.sightings.count_by_org")}
                </div>                
            </div>

            {/*Sightings count by date*/}
            <div className={`col-span-9 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>SIGHTINGS COUNT BY DATE</h1>
                {renderSightingsCountByDate()}
            </div> 


            {/*Sightings count by client*/}
            {currentReport && (getFromPath(currentReport, "content.sightings.count_by_client") as unknown as CountByClient[]).length > 1 && 
            <div className={`col-span-3 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>SIGHTINGS COUNT BY CLIENT</h1>
                {renderClientPieChart()}
            </div>            
            }

            {/*Sighted IOCs rank*/}
            <div className={`${currentReport && (getFromPath(currentReport, "content.sightings.count_by_client") as unknown as CountByClient[]).length > 1 ? "col-span-9" : "col-span-12"} ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>TOP SIGHTED IOCs</h1>
                {renderSightedIOCsRank()}
            </div>                                                                              
        </div>
    )
}

export default SherlockReport