import { useRef } from "react";
import ControlBoardContainer from "../components/controlBoard/ControlBoardContainer";
import PreviewContainer from "../components/controlBoard/PreviewContainer";
import EditorContainer from "../components/editor/EditorContainer";
import MenuBar from "../components/MenuBar";
import TopNavBar from "../components/TopNavBar";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import PageNotAccessable from "./PageNotAccessable";
import { activateFullScreenPrompt } from "../redux/fullScreenPromptSlice";
import { getDbReports } from "../service/apiAccessLogic";
import { msalInstance } from "../index"
import { setInvalidReports, setLastSyncTime } from "../redux/dataSlice";
import { getSyncTimeString } from "../service/menuLogic";
import Theme from "../Theme";
import ReportBackground from '../resource/DEFEND-Main-Contact.jpg'

function EditingPage() {
	const userMode = useAppSelector((state) => state.user.userMode)
	const previewMode = useAppSelector((state) => state.data.preview)
	const previewRef = useRef<HTMLDivElement|null>(null)
	const invalidReports = useAppSelector(state => state.data.invalidReports)
	const dispatch = useAppDispatch()
  
	if (previewMode && previewRef.current) {
		previewRef.current.scrollIntoView({ behavior: "smooth"})
	}

	if (userMode === "editor") {
		return (
		<div className={`flex ${Theme.Colors.DarkWebBg}`} onLoad={async () => {
			if (Object.keys(invalidReports).length !== 0) return 

			dispatch(activateFullScreenPrompt("loading"))
			
			await getDbReports(msalInstance, false).then((response)=>{
			dispatch(setInvalidReports(response.data))
			dispatch(activateFullScreenPrompt("false"))  
			}).catch(()=>{
			dispatch(activateFullScreenPrompt("error"))
			}) 
			
			dispatch(setLastSyncTime({time: getSyncTimeString(), type: "editor"}))
		}}>
			<MenuBar onPage="reportEditor" />
			<div className={`w-full flex flex-col items-center min-h-screen pl-44 bg-cover`} style={{ backgroundImage: `url(${ReportBackground})`}} >
				<TopNavBar />
				<div ref={previewRef} />
				<PreviewContainer />
				<ControlBoardContainer />
				<EditorContainer />
			</div>
		</div>
		)
	} else {
		return <PageNotAccessable />
	}

}

export default EditingPage;