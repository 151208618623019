import MenuBar from "../components/MenuBar";
import TopNavBar from "../components/TopNavBar";
import ViewBoardContainer from "../components/viewBoard/ViewBoardContainer";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { getDbReports } from "../service/apiAccessLogic";
import { msalInstance } from "../index"
import { setCurrentCustomerId, setLastSyncTime, setValidReports } from "../redux/dataSlice";
import { activateFullScreenPrompt } from "../redux/fullScreenPromptSlice";
import { getSyncTimeString } from "../service/menuLogic";
import Theme from "../Theme";
import ReportBackground from '../resource/DEFEND-Main-Contact.jpg'

const ReportView = () => {
    const validReports = useAppSelector(state => state.data.validReports)
    const companyId = useAppSelector(state => state.user.companyId)
    const currentCustomerId = useAppSelector(state => state.data.currentCustomerId)
    const dispatch = useAppDispatch()    
    
    return (
        <div className={`flex ${Theme.Colors.DarkWebBg}`} onLoad={async () => {
            if (currentCustomerId === "") {
                dispatch(setCurrentCustomerId(companyId))
            }
            
            if (Object.keys(validReports).length !== 0) return

            dispatch(activateFullScreenPrompt("loading"))

            await getDbReports(msalInstance, true).then((response)=>{
                dispatch(setValidReports(response.data))
                dispatch(activateFullScreenPrompt("false"))
            }).catch(()=>{
                dispatch(activateFullScreenPrompt("error"))
            })  
            
            dispatch(setLastSyncTime({time: getSyncTimeString(), type: "report"}))
        }}>
            <MenuBar onPage="reportView" />
            <div className={`w-full flex flex-col items-center min-h-screen pl-44 bg-cover`} style={{ backgroundImage: `url(${ReportBackground})`}} >
                <TopNavBar />
                <ViewBoardContainer />
            </div>
        </div>
    )
}

export default ReportView;