import Theme from "../../Theme"
import { OPTIMISATION_ACTIVITY_STATUS } from "../../models/EditorModel"
import { AlertsByClassification, AlertsByClassificationInMonth, AlertsBySeverity, AlertsBySeverityInMonth, AlertsBySourceInMonth, Counts, OptimisationActivity, WorkspaceCostAnalysis } from "../../models/ReportModel"
import { ReportCardBGStyle, ReportCardTileStyle, ReportPageStyle, TextInCardStyle } from "../../models/StyleModel"
import { useAppSelector } from "../../redux/hook"
import { getFromPath, sectionNameToUpperCase } from "../../service/dataLogic"
import { PieChartData } from "../report/PieChartColorFromData"
import PieChartForSLA from "../report/PieChartForSLA"
import PieChartNoLegends from "../report/PieChartNoLegends"
import TrendMark from "../utility/TrendMark"

const IMMonthlyReport = () => {
    const currentReport = useAppSelector((state) => state.data.currentReport)

    const renderHead = () => {
        if (currentReport === null) return 
        const company = getFromPath(currentReport, "content.company_name") as string
        const startDate = getFromPath(currentReport, "content.start_date") as string 
        const date = startDate.slice(startDate.indexOf(" ")+1)

        return (
            <div className="m-2 py-8 flex flex-col">
                <div className={`text-neutral-900`}>{company}</div>
                <div className={`text-sm ${TextInCardStyle}`}>{date}</div>
            </div>
        )
    }

    const renderCount = (path:string) => {
        if (currentReport === null) return 
        const content = getFromPath(currentReport, path) as unknown as Counts
        const trend = content.current > content.previous ? "up" : content.current === content.previous ? "equal" : "down"

        return (
            <div className="m-2 grid grid-rows-4">
                <div className={`row-span-3 text-neutral-900 py-8 text-center text-6xl`}>{content.current}</div>
                <div className={`row-span-1 place-self-center`}>
                    <TrendMark trend={trend} difference={0} /> 
                </div>
            </div>
        )
    }

    const renderStringArray = (path:string) => {
        if (currentReport === null) return 
        const content = getFromPath(currentReport, path) as unknown as string[]

        if (path.includes("recommendations")) {
            return (
                <ul className="list-disc ml-6 py-2">
                    {content.map((item,index) => (
                        <li key={index} className={`text-xs ${TextInCardStyle}`}>{item}</li>
                    ))}
                </ul>
            )
        } else {
            return (
                <div className={`m-2 py-2`}>
                    {content.map((item,index) => (
                        <div key={index} className={`text-xs ${TextInCardStyle} whitespace-pre-wrap`}>{item}</div>
                    ))}
                </div>
            )            
        }
    }

    const renderSummary = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.general.summary") as string
        return (
            <div className={`m-2 py-2 text-xs ${TextInCardStyle} whitespace-pre-wrap`}>{content}</div>
        )
    }

    const renderAlertsBySeverity = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.alerts.by_severity") as unknown as AlertsBySeverityInMonth
        const currentContent = content.current
        const previousContent = content.previous

        return (
            <div className="h-[80%] grid grid-rows-4 content-around">
                {Object.entries(currentContent).map(([key,value]) => {
                    let color = "bg-gray-500"
                    if (key === "high") {color = "bg-red-500"}
                    else if (key === "medium") {color = "bg-orange-500"}
                    else if (key === "low") {color = `${Theme.Colors.Primary}`}

                    const trend = value>previousContent[key as keyof AlertsBySeverity] ? "up" : value===previousContent[key as keyof AlertsBySeverity] ? "equal":"down"
                    return (
                    <div className="grid grid-cols-6 place-items-center" key={key}>
                        <div className={`col-span-1 ps-2 text-sm ${TextInCardStyle}`}>{value}</div>
                        <div className={`col-span-4 justify-self-start flex flex-row pl-1`}>
                            <div className={`${color} w-1 h-4 place-self-center`} />
                            <div className={`pl-1 text-sm ${TextInCardStyle}`}>{key.charAt(0).toUpperCase()+key.slice(1)}</div>                            
                        </div>
                        <div className={`col-span-1 pr-1`}><TrendMark trend={trend} difference={0} /></div>
                    </div>
                )})}
            </div>
        )
    }

    const renderAlertsByClassification = () => {
        if (currentReport === null) return 
        const content = getFromPath(currentReport, "content.alerts.by_classification") as unknown as AlertsByClassificationInMonth
        const currentContent = content.current
        const previousContent = content.previous
        
        const chartArr: PieChartData[] = []
        let color:string = "" 
        for (let key in currentContent) {
            if (key === "undetermined") continue
            else if (key === "benignpositive") {color = "rgb(147 183 83)"}
            else if (key === "falsepositive") {color = "rgb(249 115 22)"}
            else if (key === "truepositive") { color = "rgb(239 68 68)"}
            chartArr.push({id:key,label:key,value:currentContent[key as keyof AlertsByClassification],color:color})
        }

        return (
            <div className="h-[80%] grid grid-cols-5">
                <div className="col-span-2">
                    <PieChartNoLegends data={chartArr} dataType="Alerts" />                    
                </div>
                <div className="col-span-3 grid grid-rows-3 content-center">
                    {Object.entries(currentContent).filter(([key, _]) => key !== "undetermined")
                        .map(([key,value]) => {
                        
                        let color:string = ""
                        let text:string = ""
                        if (key === "truepositive") { color = "bg-red-500"; text = "True Positive"}
                        else if (key === "benignpositive") {color = `${Theme.Colors.Primary}`; text = "Benign Positive"}
                        else if (key === "falsepositive") {color = "bg-orange-500"; text = "False Positive"}

                        const trend = value>previousContent[key as keyof AlertsByClassification] ? "up" : value===previousContent[key as keyof AlertsByClassification] ? "equal":"down"
                        return (
                            <div className="grid grid-cols-6 place-items-center" key={key}>
                                <div className={`col-span-1 text-sm text-center ${TextInCardStyle}`}>{value}</div>
                                <div className={`col-span-4 justify-self-start flex flex-row`}>
                                    <div className={`${color} w-1 h-4 place-self-center`} />
                                    <div className={`pl-1 text-sm ${TextInCardStyle}`}>{text}</div>                                    
                                </div>
                                <div className={`col-span-1 pe-1`}><TrendMark trend={trend} difference={0} /></div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderAlertsBySource = () => {
        if (currentReport === null) return 
        const content = getFromPath(currentReport, "content.alerts.by_source") as unknown as AlertsBySourceInMonth
        const currentContent = content.current
        const previousContent = content.previous

        return (
            <div className="h-[80%] grid content-around">
                {currentContent.length === 0 ? 
                <div className="text-sm font-light px-2">There were no alerts this month</div>
                :
                currentContent.map((item, index) => {
                    const previousItem = previousContent.find(source => source.source === item.source)
                    let previousCount = previousItem ? previousItem.count : 0
                    const trend = item.count > previousCount ? "up" : item.count === previousCount ? "equal" : "down"
                    return (
                        <div className="grid grid-cols-7 place-items-center" key={index}>
                            <div className={`col-span-1 ps-2 text-sm ${TextInCardStyle}`}>{item.count}</div>
                            <div className={`col-span-5 justify-self-start flex flex-row`}>
                                <div className="bg-purple-900 w-1 h-4 place-self-center" />
                                <div className={`pl-2 w-fit text-xs ${TextInCardStyle}`}>{item.source.toUpperCase()}</div>                            
                            </div>
                            <div className={`col-span-1 pr-1`}><TrendMark trend={trend} difference={0} /></div>
                        </div>                        
                    )
                })}
            </div>
        )
    }

    const renderOptimisationActivity = () => {
        if (currentReport === null) return 
        const content = getFromPath(currentReport, "content.general.optimisation_activity") as unknown as OptimisationActivity[]
        let sorted = [...content].sort((a,b) => b.status - a.status)

        return (
            <div className="w-full h-[80%] flex justify-between pb-2">
                {sorted.length === 0 ?
                    <div className="text-sm font-light px-2">There were no optimisation activities this month</div>
                    :
                    <table className="w-full border-separate border-spacing-1">
                        <thead className="bg-gray-500">
                            <tr>
                                <th className="rounded-sm text-sm font-medium">ACTIVITY</th>
                                <th className="rounded-sm text-sm font-medium">STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sorted.map((activity, rowIndex) => {
                                let rowColor:string = ""
                                if (rowIndex % 2 === 0) {rowColor = "bg-gray-300"}
                                else {rowColor = "bg-gray-100"}

                                let statusColor:string = ""
                                let statusText:string = ""
                                if (activity.status === 1) {statusColor = `${Theme.Colors.Primary}`; statusText = OPTIMISATION_ACTIVITY_STATUS[activity.status]}
                                else if (activity.status === 2) {statusColor = "bg-purple-500"; statusText = OPTIMISATION_ACTIVITY_STATUS[activity.status]}
                                else if (activity.status === 3) {statusColor = "bg-orange-500"; statusText = OPTIMISATION_ACTIVITY_STATUS[activity.status]}
                                else if (activity.status === 4) {statusColor = "bg-yellow-500"; statusText = OPTIMISATION_ACTIVITY_STATUS[activity.status]}
                                else if (activity.status === 5) {statusText = OPTIMISATION_ACTIVITY_STATUS[activity.status]}
                                return (
                                    <tr className={`${rowColor}`} key={rowIndex}>
                                        <td className={`rounded-sm text-xs pl-1`}>{activity.name}</td>
                                        <td className={`rounded-sm text-xs text-center ${statusColor}`}>{statusText}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>                     
                }
            </div>
        )
    }

    const renderSLATicketsCount = () => {
        if (currentReport === null) return 
        const totalCount = getFromPath(currentReport, "content.general.total_ticket") as number
        const slaCount = getFromPath(currentReport, "content.general.sla_breach_ticket") as number
        const nonSlaCount : number = totalCount - slaCount
        const nonSlaPercent = totalCount === 0 ? "100" : ((nonSlaCount * 1.0 / totalCount) * 100).toFixed(0)
        const chartData : PieChartData[] = [
            {
                id: "SLA",
                label: "SLA",
                value: slaCount,
                color: "rgb(249 115 22)"
            },
            {
                id: `${nonSlaPercent}%`,
                label: `${nonSlaPercent}%`,
                value: nonSlaCount,
                color: "rgb(147 183 83)"
            }
        ]

        const summary = slaCount === 0 ? 
            "There were no breaches of time to first response SLAs this month"
            :
            `There ${slaCount > 1 ? "were" : "was"} ${slaCount} ${slaCount > 1 ? "breaches" : "breach"} of time to first response SLAs this month`

        return (
            <div className="w-full h-[80%] py-6 text-center flex flex-col">
                <PieChartForSLA data={chartData} />
                <div className="text-sm font-light">{summary}</div>
            </div>
        )
    }

    const renderCostAnalysis = () => {
        if (currentReport === null) return
        const content = getFromPath(currentReport, "content.workspace_cost_analysis") as unknown as WorkspaceCostAnalysis
        if (!content) return
        return (
            <div className="flex flex-col px-4 pb-4 gap-2">
                {Object.entries(content).map(([key, value]) => {
                    const text = sectionNameToUpperCase(value.workspace_name);
                    const totalCost = value.total_cost.toFixed(2);
                    const avgCost = value.avg_cost.toFixed(2);
                    const preCost1 = value.pre_total_cost
                    const preCost2 = value.cost_two_months_ago
                    const preCost3 = value.cost_three_months_ago

                    const costTrend = preCost1 === 0 ? 
                        "equal" : value.total_cost > preCost1 ? 
                            "up" : value.total_cost === preCost1 ? 
                                "equal" : "down"

                    let maxDiff = 0

                    if (costTrend === "up" && preCost1 !== 0) {
                        maxDiff = [preCost1, preCost2, preCost3].reduce((accumulator, current) => {
                                if (current === 0) {
                                    return accumulator
                                } else {
                                    return Math.max(accumulator, (value.total_cost / current - 1))
                                }
                            }, 0)
                    }
                    
                    return (
                        <div className="flex flex-col" key={key}>   
                            <div className="flex flex-col justify-between">
                                <div className={`flex font-light text-sm text-neutral-900`}>Workspace:</div>
                                <div className={`flex font-light text-sm text-neutral-900 overflow-x-auto py-2`}>{text}</div>
                            </div>  
                            { value.data_ingestion &&
                            <div className="grid grid-cols-6 items-center">
                                <div className="col-span-5 flex flex-row justify-between">
                                    <div className={`font-light text-sm text-neutral-900 `}>Data:</div>
                                    <div className={`font-light text-sm text-neutral-900 `}>{value.data_ingestion.toFixed(2)}</div>                                    
                                </div>

                                <div className={`col-span-1 font-light text-sm text-neutral-900 pr-1 place-self-center`} >GB</div>
                            </div>                             
                            }                               
                            <div className="grid grid-cols-6 items-center">
                                <div className="col-span-5 flex flex-row justify-between">
                                    <div className={`font-light text-sm text-neutral-900 `}>Total:</div>
                                    <div className={`font-light text-sm text-neutral-900 `}>${totalCost}</div>                                    
                                </div>

                                <div className={`col-span-1 pr-1 place-self-center`}><TrendMark trend={costTrend} difference={maxDiff} /></div>
                            </div> 
                            <div className="grid grid-cols-6 items-center">
                                <div className="col-span-5 flex flex-row justify-between">
                                    <div className={`font-light text-sm text-neutral-900 `}>Average:</div>
                                    <div className={`font-light text-sm text-neutral-900 `}>${avgCost}</div>                                    
                                </div>

                                <div className={`col-span-1 pr-1 place-self-center`} />
                            </div>
                            {/* {value.cost_anomaly_detected ? 
                                <div className={`font-light text-sm text-neutral-900 text-center pt-2`}>Cost is higher than estimated</div>
                                : <div className={`font-light text-sm text-neutral-900 text-center pt-2`}>Cost is within normal range</div>
                            } */}
                            {/* <div className="flex border-b-[1px] border-b-neutral-900 align-center" key={key} /> */}
                        </div>
                    );
                })}
            </div>
        )
    }

    const renderActivitySummary = () => {
        if (currentReport === null) return 
        const totalTickets = getFromPath(currentReport, "content.general.total_ticket") as unknown as number
        const pendingTickets = getFromPath(currentReport, "content.general.pending_tickets") as unknown as number
        const totalAlerts = getFromPath(currentReport, "content.general.total_alerts") as unknown as number
        const pendingAlerts = getFromPath(currentReport, "content.general.pending_alerts") as unknown as number

        if (totalAlerts === undefined) {
            return 
        }

        const activitySummary = {
            "Total Alerts": totalAlerts,
            "Pending Alerts": pendingAlerts,
            "Total Tickets": totalTickets,
            "Pending Tickets": pendingTickets
        }

        return (
            <div className="h-[80%] flex flex-col justify-between p-4 gap-2">
                {Object.entries(activitySummary).map(([key, value]) => {
                    return(
                        <div className="w-[90%] flex flex-row justify-between border-b-[1px] border-b-neutral-900" key={key}>
                            <div className={`font-light text-sm text-neutral-900 `}>{key}</div>
                            <div className={`font-light text-sm text-neutral-900 `}>{value}</div>
                        </div>
                    )
                })}
            </div>
        )
    }
        
    return (
        <div id="ReportContainer" className={`${ReportPageStyle} w-full aspect-w-16 aspect-h-9 grid grid-cols-12 gap-2`}>
            {/* ROW 1 */}
            {/*Head*/}
            <div className="col-span-2 relative">
                <div className={`m-2 text-2xl text-neutral-900 underline underline-offset-4 decoration-ui-lime`}>DEFEND iCE</div>
                <h3 className="m-2 text-xs text-neutral-900">Incident Management, Handling and Response</h3>
                {renderHead()}
            </div>
            {/*Incidents*/}
            <div className={`col-span-2 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>INCIDENTS</h1>
                {renderCount("content.events.incidents.counts")}
            </div>  
            {/*Incidents overview*/}
            <div className={`col-span-3 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>INCIDENTS OVERVIEW</h1>
                {renderStringArray("content.events.incidents.overview")}
            </div>                      
            {/*Near misses*/}
            <div className={`col-span-2 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>NEAR MISSES</h1>
                {renderCount("content.events.near_misses.counts")}
            </div>            
            {/*Near miss overview*/}
            <div className={`col-span-3 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>NEAR MISS OVERVIEW</h1>
                {renderStringArray("content.events.near_misses.overview")}
            </div>  

            {/* ROW 2 */}
            {/*SLA*/}
            <div className={`col-span-2 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>SLA - Time To First Response</h1>
                {renderSLATicketsCount()}
            </div>             
            {/*Alert report*/}
            <div className={`col-span-2 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>ALERT REPORT</h1>
                {renderAlertsBySeverity()}
            </div>            
            {/*Alert classification*/}
            <div className={`col-span-4 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>ALERT CLASSIFICATION</h1>
                {renderAlertsByClassification()}
            </div>            
            {/*MS Alert sources*/}
            <div className={`col-span-4 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>ALERT SOURCES</h1>
                {renderAlertsBySource()}
            </div>  
 

            {/* ROW 3 */}
            {/*Activity Summary*/}
            {currentReport && (getFromPath(currentReport, "content.general") as unknown as Object).hasOwnProperty("total_alerts") && 
                <div className={`col-span-2 ${ReportCardBGStyle} relative`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>Activity Summary</h1>
                    {renderActivitySummary()}
                </div>                
            }
                        
            <div className={`${currentReport && (getFromPath(currentReport, "content.general") as unknown as Object).hasOwnProperty("total_alerts") ?
                "col-span-2" : "col-span-3"} relative flex flex-col gap-2`}>
                {/*Recommendations*/}
                <div className={`${ReportCardBGStyle} relative`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>RECOMMENDATIONS</h1>
                    {renderStringArray("content.general.recommendations")}
                </div>  
                {/*Cost Analysis*/}          
                <div className={`grow ${ReportCardBGStyle} relative`}>
                    <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>COST ANALYSIS (ESTIMATED)</h1>
                    {renderCostAnalysis()}
                </div>                  
            </div>
           
            {/*Optimisation activity*/}
            <div className={`${currentReport && (getFromPath(currentReport, "content.general") as unknown as Object).hasOwnProperty("total_alerts") ?
                "col-span-4":"col-span-5"} ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>OPTIMISATION ACTIVITY</h1>
                {renderOptimisationActivity()}
            </div>

            {/*Summary*/}
            <div className={`col-span-4 ${ReportCardBGStyle} relative`}>
                <h1 className={`${Theme.Colors.Primary} ${ReportCardTileStyle}`}>SUMMARY</h1>
                {renderSummary()}
            </div>   
        </div>
    )
}

export default IMMonthlyReport