import { Link } from 'react-router-dom';

const Breadcrumb = ({ pathSegments, startFrom }: any) => {
  const startIndex = pathSegments.indexOf(startFrom);
  const relevantSegments = pathSegments.slice(startIndex);

  return (
    <div className="breadcrumbs">
      {relevantSegments.map((segment: string, index: number) => (
        <span className={`text-lime-500 hover:text-lime-200`} key={index}>
          <span className={`text-gray-500`}>{index !== 0 && " / "}</span>
          <Link to={`/${relevantSegments.slice(0, index + 1).join("/")}`}>
            {segment}
          </Link>
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;