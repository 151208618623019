import { ResponsiveLine } from "@nivo/line"

export type LineChartDataType = {x:string;y:number}
export interface LineChartData {
    id: string
    data: LineChartDataType[]
    color?: string
}

const LineChart = ({data}:{data:LineChartData[]}) => {
    return (
        <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
        xScale={{ 
            type: 'time',
            format: '%d/%m',
            precision: 'day',
            useUTC: false 
        }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        xFormat="time:%d/%m"
        yFormat=" >-.0f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            format: '%d/%m',
            tickValues: "every 2 days"
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
    />)
}

export default LineChart