import { ResponsivePie } from "@nivo/pie"
import { PieChartData } from "./PieChartColorFromData"

const PieChartForSLA = ({data}: {data:PieChartData[]}) => {
    const isAllZeros = data.every(entry => entry.value === 0)
    let displayData = data

    if (isAllZeros) {
        displayData = [{id: "100%", label: "100%", value: 1, color: "rgb(147 183 83)"},
            {id: "SLA", label: "SLA", value: 0, color: "rgb(249 115 22)"}]
    }

    return (
        <ResponsivePie
            data={displayData}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={{ datum: "data.color" }}
            enableArcLabels={!isAllZeros}
            arcLabel={(data) => (data.value > 0 ? `${data.value}` : "")}
            enableArcLinkLabels={true}
            arcLinkLabel={(data) => (data.id === "SLA" ? "" : data.id === "0%" ? "" : `${data.id}`)}
            arcLinkLabelsTextColor="#93b753"
            arcLinkLabelsThickness={0}
            arcLinkLabelsOffset={-70}
            arcLinkLabelsTextOffset={-40}
        />)
}

export default PieChartForSLA