
export type TrendMarkProp = "equal"|"up"|"down"

const TrendMark = ({trend, difference}:{trend:TrendMarkProp, difference:number}) => {
    let markColor = ""
    if (difference === 0) {
        markColor = "border-b-red-500"
    } else if (difference >= 0.4) {
        markColor = "border-b-red-500"
    } else if (difference >= 0.3) {
        markColor = "border-b-amber-500"
    } else if (difference >= 0.2) {
        markColor = "border-b-cyan-500"
    } else if (difference >= 0.1) {
        markColor = "border-b-lime-500"
    } else {
        markColor = "border-b-gray-500"
    }
    
    return (
        <div>
            {{
                "equal":
                <div className="text-center text-neutral-900 text-lg font-bold">--</div>,
                "up":
                <div className={`w-fit border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] ${markColor}`} />,
                "down":
                <div className="border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-ui-lime" />
                }[trend]
            }
        </div>
    )
}

export default TrendMark