import { useReactTable, ColumnResizeMode, getCoreRowModel, ColumnDef, flexRender, PaginationState, ExpandedState, Row, getExpandedRowModel } from '@tanstack/react-table'
import { AuditLogData } from '../../redux/metaDataSlice'
import { useAppSelector } from '../../redux/hook'
import { Fragment, useMemo, useState } from 'react'

const defaultColumns: ColumnDef<AuditLogData>[] = [
  {
    header: "Audit Logs",
    footer: (props) => props.column.id,
    columns: [
      {
        accessorKey: "timestamp",
        header: () => <span>Timestamp</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "user",
        header: () => <span>User</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "resource",
        header: () => <span>Resource</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "resource_id",
        header: () => <span>Resource ID</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "payload",
        header: () => <span>Content</span>,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: "pointer" },
              }}
            >
              {row.getIsExpanded() ? "- Hide Content" : "+ See Content"}
            </button>
          ) : (
            "No Details Available"
          );
        },
        footer: (props) => props.column.id,
      },
    ],
  },
];

const AuditLogsView = () => {
    const auditLogs = useAppSelector(state => state.metaData.auditLogs)

    const [columns, setColumns] = useState<ColumnDef<AuditLogData>[]>(defaultColumns)  
    const [columnResizeMode] = useState<ColumnResizeMode>("onChange")
    const [expanded, setExpanded] = useState<ExpandedState>({});

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 25
    })      

    const pagedAuditLogs = auditLogs.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)

    const pagination = useMemo(() => ({
        pageIndex,
        pageSize
    }), [pageIndex, pageSize])

    const getRowCanExpand = ( row: Row<AuditLogData>) => {
      return true
    };
        
    const table = useReactTable({
        data: pagedAuditLogs || [],
        columns,
        pageCount: Math.ceil(auditLogs.length / pageSize),
        state: { pagination, expanded },
        onPaginationChange: setPagination,
        onExpandedChange: setExpanded,
        manualPagination: true, 
        columnResizeMode,
        getRowCanExpand,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel()

    })    

    return (
      <div
        className={`w-full p-3 shadow-2xl bg-white bg-opacity-90 overflow-x-auto`}
      >
        {/* Table body */}
        <table className="w-full border border-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="w-auto h-7">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`border border-gray-300 p-1 text-center font-bold h-7 relative`}
                    style={{
                      width: header.getSize(),
                      whiteSpace: "nowrap",
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <div
                      className={`absolute right-0 top-0 h-full w-1.5 bg-gray-700 cursor-col-resize select-none ${
                        header.column.getIsResizing()
                          ? "bg-blue-500 opacity-100"
                          : "opacity-0"
                      }`}
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      style={{
                        transform:
                          columnResizeMode === "onEnd" &&
                          header.column.getIsResizing()
                            ? `translateX(${
                                table.getState().columnSizingInfo.deltaOffset
                              }px)`
                            : "",
                      }}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr key={row.id} className="w-auto h-7">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="border border-gray-300 h-7"
                      style={{
                        width: cell.column.getSize(),
                        padding: "0 6px",
                        whiteSpace: "normal",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() && (
                  <tr key={`${row.id}-expanded`} className="w-auto h-7">
                    <td
                      colSpan={table.getVisibleFlatColumns().length}
                      className="border border-gray-300 h-7"
                    >
                    {row.getValue("payload")}
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>

        {/* Pagination controller */}
        <div className="flex items-center gap-2">
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => {
              table.nextPage();
            }}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1);
            }}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border p-1 rounded w-16"
            />
          </span>
        </div>
      </div>
    );    
}

export default AuditLogsView